/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const iraContributionTypeUpdate = {
  displayName: 'IRA Contribution Type Update',
  permission: 'IRA_CONTRIBUTION_TYPE_UPDATE_BATCH_CREATE',
  csvInputs: {
    accountno: {
      label: 'Account No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountNo',
    },
    paymentid: {
      label: 'Payment ID',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'paymentID',
    },
    iracontribution: {
      label: 'IRA Contribution',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'iraContribution',
    },
    amount: {
      label: 'Amount',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'amount',
    },
  },
  checks: {
    columns: {
      batchEnabled: { value: true },
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountNo: 'KRFZ000001',
      paymentID: 'KRFZ000001-1689617913237-DLBUM',
      iraContribution: 'Roth',
      amount: 10000,
    },
  ],
};

export { iraContributionTypeUpdate };
