//
import { Box, Button } from '@drivehub/ui-components';
import { alpha } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { copyText } from 'app/internationalization/copyText';
import { uniqBy } from 'lodash';
import memoize from 'memoize-one';
import Color from 'modules/Color';
import React from 'react';
import mousetrap from 'react-mousetrap';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import resultTypes from 'reducers/search/resultTypes';
import previousResultType from 'reducers/search/resultTypes/previous';
import styled from 'styled-components';

import { history } from '../../../../app/utils';

const {
  common: { noResultsFound },
} = copyText;

const COLOR_BG = new Color('#FFF');

const Category = styled.div`
  padding: 1em 0;
`;

const CategoryHeader = styled.div`
  padding: 0.5em 2em;
  text-transform: uppercase;
  font-weight: 500;
  color: ${COLOR_BG.adjust(0.3).hex};
`;

const CategoryCount = styled.span`
  font-size: 0.9em;
  background-color: ${COLOR_BG.adjust(0.1).hex};
  color: ${COLOR_BG.adjust(0.6).hex};
  border-radius: 20px;
  padding: 0.2em 0.7em;
  margin-right: 0.6em;
`;

const Result = styled(Link)`
  display: block;
  background-color: ${props => props.theme.dw.colors.corePrimary3};
  padding: 1em 2em;

  ${props =>
    props.isKeyboardHighlighted &&
    `
		background-color: ${props => alpha(props.theme.dw.colors.primary3, 0.3)};
	`}

  &:hover {
    background-color: ${props => alpha(props.theme.dw.colors.primary3, 0.3)};
  }
`;

const TopTexts = styled.div`
  display: flex;
  flex-direction: row;
`;

const Primary = styled.div`
  font-size: 1.1em;
  color: ${props => props.theme.dw.colors.white};
  flex: 1;
`;

const Secondary = styled.div`
  color: ${props => alpha(props.theme.dw.colors.white, 0.6)};
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Tertiary = styled.div`
  max-width: 30%;
  white-space: nowrap;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em;
  font-weight: 500;
  color: ${COLOR_BG.adjust(0.4).hex};
`;

const NoResults = styled.div`
  padding: 1.5em 2em;
  color: ${props => props.theme.dw.colors.white};
`;

const resultTypeMap = Object.assign(
  {},
  { [previousResultType.id]: previousResultType },
  ...resultTypes.map(resultType => ({ [resultType.id]: resultType })),
);

const KeyboardAwareResultsTpl = props => {
  const [storedPreviousResults, setStoredPreviousResults, removeStoredPreviousResults] =
    useLocalStorage('storedPreviousResults', localStorage.getItem('storedPreviousResults'));

  const {
    isFetching,
    isLoading,
    results,
    resultsCount,
    clearHighlightedResult,
    keyboardSelectedResult,
  } = props;

  const accountUndefined = results?.ACCOUNT && results.ACCOUNT[0].path.includes('undefined');
  const userUndefined = Object.keys(results)?.length === 1 && accountUndefined;

  if (Object.keys(results)?.length > 1 && accountUndefined) {
    delete results.ACCOUNT;
  }

  const storePreviousResult = result => {
    return () => {
      if (storedPreviousResults) {
        const newStoredItems = uniqBy([result, ...storedPreviousResults.PREVIOUS], 'primary').slice(
          0,
          5,
        );
        setStoredPreviousResults({ PREVIOUS: newStoredItems });
        return;
      }
      setStoredPreviousResults({ PREVIOUS: [result] });
    };
  };

  return (
    <>
      {!userUndefined &&
        Object.keys(results).map(category => (
          <Category key={category}>
            <CategoryHeader>
              <CategoryCount data-testid="count">{results[category].length}</CategoryCount>
              <span data-testid="category">{category}</span>
              {category === 'PREVIOUS' && (
                <Button
                  onClick={removeStoredPreviousResults}
                  sx={{ marginLeft: '56px' }}
                  variant="text"
                  size="small"
                >
                  Clear all
                </Button>
              )}
            </CategoryHeader>
            {results[category].map(result => (
              <Result
                onClick={storePreviousResult(result)}
                to={result.path}
                key={result.keyboardIndex}
                isKeyboardHighlighted={keyboardSelectedResult === result.keyboardIndex}
                onMouseOver={clearHighlightedResult}
                data-testid="result"
              >
                <TopTexts>
                  <Primary data-testid="primary">{result.primary}</Primary>
                  <Tertiary data-testid="tertiary">{result.tertiary}</Tertiary>
                </TopTexts>
                <Secondary data-testid="secondary">{result.secondary}</Secondary>
              </Result>
            ))}
          </Category>
        ))}
      {/* The lines below are for displaying a progress indicator 
        and for preventing "No results found" from flashing for a split second */}
      {((!isFetching && isLoading) || isFetching) && (
        <Box display="flex" justifyContent="center" alignItems="center" height="5em">
          <CircularProgress color="primary" />
        </Box>
      )}
      {(resultsCount === 0 || userUndefined) && !isFetching && !isLoading ? (
        <NoResults>{noResultsFound}</NoResults>
      ) : null}
    </>
  );
};

const indexResults = memoize(results => {
  let currentIdx = -1;
  const newResults = {};
  const idToPath = {};

  for (const [category, items] of Object.entries(results)) {
    const newItems = [];
    // When we have a full search results page we will use maxSuggestions
    // for (const item of items.slice(0, resultTypeMap[category].maxSuggestions)) {
    // $FlowFixMe - Flow doesnt know about Object.entries
    for (const item of items.slice(0, 100)) {
      currentIdx++;
      newItems.push({
        ...item,
        keyboardIndex: currentIdx,
      });
      idToPath[currentIdx] = item.path;
    }
    newResults[category] = newItems;
  }

  return {
    results: newResults,
    count: currentIdx + 1,
    idToPath,
  };
});

class KeyboardAwareResults extends React.Component {
  constructor(props) {
    super(props);

    const { results, count, idToPath } = indexResults(props.results);
    this.indexedResults = results;
    this.suggestionsCount = count;
    this.idToPath = idToPath;
  }

  state = {
    keyboardSelectedResult: -1,
  };

  componentDidMount() {
    this.bindShortcuts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      if (this.props.isActive) {
        this.bindShortcuts();
      } else {
        this.unbindShortcuts();
      }
    }
  }

  indexedResults;
  suggestionsCount;
  idToPath;

  bindShortcuts() {
    this.props.bindShortcut('down', this.highlightNextResult);
    this.props.bindShortcut('up', this.highlightPrevResult);
    this.props.bindShortcut('enter', this.keyboardSelectResult);
  }

  unbindShortcuts() {
    this.props.unbindShortcut('down');
    this.props.unbindShortcut('up');
    this.props.unbindShortcut('enter');
  }

  highlightResultWithID(id) {
    this.setState({
      keyboardSelectedResult: id,
    });
  }

  clearHighlightedResult = () => {
    this.setState({
      keyboardSelectedResult: -1,
    });
  };

  highlightNextResult = () => {
    if (this.state.keyboardSelectedResult >= this.suggestionsCount - 1) {
      this.highlightResultWithID(0);
    } else {
      this.highlightResultWithID(this.state.keyboardSelectedResult + 1);
    }

    return false;
  };

  highlightPrevResult = () => {
    if (this.state.keyboardSelectedResult <= 0) {
      this.highlightResultWithID(this.suggestionsCount - 1);
    } else {
      this.highlightResultWithID(this.state.keyboardSelectedResult - 1);
    }

    return false;
  };

  keyboardSelectResult = () => {
    if (this.state.keyboardSelectedResult === -1) {
      const resultTypes = Object.keys(this.indexedResults);
      if (resultTypes.length) {
        const results = this.indexedResults[resultTypes[0]];
        history.push(results[0].path);
      }
    } else {
      history.push(this.idToPath[this.state.keyboardSelectedResult + '']);
    }
  };

  render() {
    return (
      <KeyboardAwareResultsTpl
        resultsCount={this.props.resultsCount}
        {...this.state}
        results={this.indexedResults}
        clearHighlightedResult={this.clearHighlightedResult}
        isFetching={this.props.isFetching}
        isLoading={this.props.isLoading}
      />
    );
  }
}

export default mousetrap(KeyboardAwareResults);
