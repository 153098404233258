import { ChangeEvent, useCallback, useRef, useState } from 'react';
import useMousetrap from 'react-hook-mousetrap';

export const useNavSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => {
    inputRef.current?.focus();
    setIsOpen(true);
  }, []);

  // This method closes the suggestion box and leaves the search bar open and unfocused
  const handleClose = useCallback(() => {
    inputRef.current?.blur();
    setIsOpen(false);
  }, []);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsOpen(true);
    setQuery(event.currentTarget.value);
  }, []);

  const unfocusSeachBar = useCallback(() => {
    inputRef.current?.blur();
    setIsOpen(false);
  }, []);

  useMousetrap('/', handleOpen, 'keyup');
  useMousetrap('esc', unfocusSeachBar);

  return {
    handleChange,
    handleClose,
    handleOpen,
    query,
    inputRef,
    isOpen,
    setIsOpen,
    unfocusSeachBar,
  };
};
