import { Divider } from '@mui/material';
import type {
  AccountSearch,
  DepositDetail,
  InstrumentSearch,
  OrderSearch,
  Previous,
  SearchResults,
  UserSearch,
  WithdrawalDetail,
} from 'app/types';
import { CopyText } from 'common/constants/copyText';
import { identity, pickBy } from 'lodash';
import memoize from 'memoize-one';
import Color from 'modules/Color';
import { useLocalStorage } from 'react-use';
import styled from 'styled-components';

import KeyboardAwareResults from './KeyboardAwareResults';
import { TipsNew } from './TipsNew';

const { ENTER_CHAR_RESTRICT } = CopyText;
const TIPS_BG = new Color('#FFF');

const Container = styled.div<{ isActive: boolean }>`
  background-color: ${props => props.theme.dw.colors.corePrimary3};
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  pointer-events: none;
  opacity: 0;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translate3d(0, -10%, 0);
  z-index: 450;
  transition: ${props =>
    props.isActive
      ? props.theme.transitions.create(['opacity', 'transform'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.enteringScreen,
        })
      : props.theme.transitions.create(['opacity', 'transform'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.leavingScreen,
        })};
  user-select: text;
  font-weight: normal;
  overflow: auto;
  max-height: 80vh;

  ${props =>
    props.isActive &&
    `
		pointer-events: all;
		opacity: 1;
		transform: translate3d(0, 0, 0);
		z-index: 600;
	`}
`;

const StyledPara = styled.p`
  color: ${TIPS_BG.adjust(0.4).toString()};
  padding: 10px;
  &::before {
    content: '';
    display: inline-block;
  }
`;

const StyledDivider = styled(Divider)`
  background-color: ${props => props.theme.dw.colors.corePrimary5};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getKeyForResultSet = memoize((_results: SearchResults & Previous) => String(Date.now()));

type SearchSuggestionsProps = {
  results?: SearchResults;
  query: string;
  isActive: boolean;
  isFetching: boolean;
  isLoading: boolean;
};

type StoredItems = {
  PREVIOUS: Previous;
};

const SearchSuggestions = (props: SearchSuggestionsProps) => {
  const [storedItems] = useLocalStorage(
    'storedPreviousResults',
    localStorage.getItem('storedPreviousResults'),
  );

  const { results: searchData, query: searchQuery, isActive, isFetching, isLoading } = props;

  const newClientData = // CLIENT
    searchData?.users &&
    searchData.users.length > 0 &&
    searchData.users.map((user: UserSearch, index: number) => ({
      path: `/users/${user.id}`,
      primary: `${user.firstName} ${user.lastName}`,
      secondary: user.username,
      tertiary: user.wlpID,
      keyboardIndex: index,
    }));

  const newAccountData = // ACCOUNT
    searchData?.account &&
    Object.keys(searchData.account).length > 0 &&
    [searchData.account].map((account: AccountSearch, index: number) => ({
      path: `/users/${account.userID}/accounts/${account.id}`,
      primary: account.accountNo,
      secondary: `${account.firstName} ${account.lastName}`,
      keyboardIndex: index,
    }));

  const newInstrumentData = // INSTRUMENT
    searchData?.instruments &&
    searchData.instruments.length > 0 &&
    searchData.instruments.map((instrument: InstrumentSearch, index: number) => ({
      path: `/instruments/${instrument.id}`,
      primary: instrument.symbol,
      secondary: instrument.name,
      keyboardIndex: index,
    }));

  const newOrderData = // ORDER
    searchData?.order &&
    Object.keys(searchData.order).length > 0 &&
    [searchData.order].map((order: OrderSearch, index: number) => ({
      path: `/reporting/ticket-audit/${order.orderNo}`,
      primary: order.orderNo,
      secondary: `${order.symbol}, ${order.created}`,
      tertiary: order.accountNo,
      keyboardIndex: index,
    }));

  const withdrawalData =
    searchData?.withdrawal &&
    Object.entries(searchData.withdrawal).length > 0 &&
    [searchData.withdrawal].map((withdrawal: WithdrawalDetail, index: number) => ({
      path: `/reporting/payment-audit/${withdrawal.id}`,
      primary: withdrawal.id,
      keyboardIndex: index,
    }));

  const depositData =
    searchData?.deposit &&
    Object.entries(searchData.deposit).length > 0 &&
    [searchData.deposit].map((deposit: DepositDetail, index: number) => ({
      path: `/reporting/payment-audit/${deposit.id}`,
      primary: deposit.id,
      keyboardIndex: index,
    }));

  const newResults = {
    USER: newClientData,
    ACCOUNT: newAccountData,
    INSTRUMENT: newInstrumentData,
    ORDER: newOrderData,
    WITHDRAWAL: withdrawalData,
    DEPOSIT: depositData,
  };

  const cleanedNewResults = pickBy(newResults, identity);

  const allSearchResults = storedItems
    ? { ...(storedItems as unknown as StoredItems), ...cleanedNewResults }
    : cleanedNewResults;

  const newResultsCount = Object.values(cleanedNewResults).flat().length;

  const allSearchResultsCount = storedItems
    ? (storedItems as unknown as StoredItems).PREVIOUS.length + newResultsCount
    : newResultsCount;

  return (
    <Container isActive={isActive}>
      {!searchQuery || (searchQuery && searchQuery.length < 1) ? (
        <>
          <StyledPara>{ENTER_CHAR_RESTRICT}</StyledPara>
          <StyledDivider variant="middle" sx={{ position: 'relative', top: '24px' }} />
          <TipsNew />
        </>
      ) : (
        <>
          <KeyboardAwareResults
            key={getKeyForResultSet(allSearchResults)}
            isActive={props.isActive}
            results={allSearchResults}
            resultsCount={allSearchResultsCount}
            isFetching={isFetching}
            isLoading={isLoading}
          />
        </>
      )}
    </Container>
  );
};

export default SearchSuggestions;
