import { ListItem, ListItemProps } from '@mui/material';
import { ElementType, ReactElement } from 'react';
import { styled } from '@mui/material/styles';

import { Variant } from './types';
import { getBackgroundColor, getLeftMargin, getLeftPadding, getListItemColor } from './utils';

interface NavListItemInternalProps {
  isActive?: boolean;
  variant: Variant;
}

export type NavListItemProps<C extends ElementType> = ListItemProps<C, { component?: C }> &
  NavListItemInternalProps;

const StyledNavListItem = styled(({ isActive, variant, ...props }: NavListItemInternalProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ListItem {...props} />
))`
  cursor: pointer;

  padding-left: ${props => getLeftPadding(props.variant, props.theme.spacing)};
  padding-top: ${props => props.theme.spacing(0.5)};
  padding-bottom: ${props => props.theme.spacing(0.5)};
  margin-left: ${props => getLeftMargin(props.variant)};
  max-height: ${props => props.theme.spacing(5)};
  background-color: ${({ isActive = false, variant, theme }) =>
    getBackgroundColor(isActive, variant, theme)};
  border-radius: ${({ variant }) => (variant !== 'primary' ? '4px' : '0')};
  width: ${({ variant }) => (variant === 'primary' ? '100%' : 'auto')};

  svg {
    color: ${getListItemColor};
  }

  .MuiListItemText-primary {
    color: ${getListItemColor};
  }

  &:hover {
    svg {
      color: ${props => props.theme.dw.colors.white};
    }

    .MuiListItemText-primary {
      color: ${props => props.theme.dw.colors.white};
    }
  }

  &:focus {
    svg {
      color: ${props => props.theme.dw.colors.white};
    }

    .MuiListItemText-primary {
      color: ${props => props.theme.dw.colors.white};
    }
  }
` as typeof ListItem;

// TypeScript Wrapper for a component that takes a generic material-ui component prop
export function NavListItem<C extends ElementType>(props: NavListItemProps<C>): ReactElement {
  const { button, isActive, ...rest } = props;

  if (button) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <StyledNavListItem {...rest} isActive={isActive} button />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledNavListItem {...rest} />;
}
