import { Box } from '@drivehub/ui-components';
import { Chip, Typography } from '@mui/material';
import styled from '@mui/system/styled';
import { copyText } from 'app/internationalization';

const {
  common: { newCaps },
  navbar: { searchForEquitiesAndMutualFunds },
} = copyText;

const Container = styled('div')(
  ({ theme: { palette } }) => `
    padding: 5em 2em;
    align-items: center;
    justify-content: center;
    background-color: ${palette.colors.corePrimary3};
  `,
);

const StyledChip = styled(Chip)(
  ({ theme: { palette } }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: none;
    background-color: ${palette.colors.brandBase};
    height: 16px;
    width: 32px;
    margin-top: 4px;

    &.MuiChip-root {
      border-radius: 3px;
    }
  `,
);

export const TipsNew = () => (
  <Container>
    <Box display="flex" alignItems="flex-start" gap={1.5}>
      <StyledChip
        label={
          <Typography
            variant="caption"
            position="relative"
            bottom={2}
            color="colors.corePrimaryBase"
          >
            {newCaps}
          </Typography>
        }
      />
      <Typography
        variant="body2"
        fontSize="11px"
        sx={{ color: 'colors.corePrimary5', lineHeight: 1.5 }}
      >
        {searchForEquitiesAndMutualFunds}
      </Typography>
    </Box>
  </Container>
);
