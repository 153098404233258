import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { fetch } from 'src/app/services';

import { SearchResults } from '../types';

type Error = {
  errorCode?: number;
  message: string;
  status?: number;
  statusText?: string;
};

export const useGlobalSearch = (
  params: string,
  options: UseQueryOptions<SearchResults, Error>,
): UseQueryResult<SearchResults, Error> => {
  return useQuery<SearchResults, Error>({
    queryKey: ['search', params],
    queryFn: async () => {
      const endpoint = new URL('/api/search', window.location.origin);

      endpoint.searchParams.append('searchTerm', params);

      const res = await fetch(endpoint.href);

      return res.json() as Promise<SearchResults>;
    },
    ...options,
  });
};
