

export const RIATypes = {
  RIA_MANAGED: 'RIA_MANAGED',
  CUSTODIAL_MANAGED: 'CUSTODIAL_MANAGED',
  RETIREMENT_TRADITIONAL_RIA_MANAGED: 'RETIREMENT_TRADITIONAL_RIA_MANAGED',
  RETIREMENT_ROTH_RIA_MANAGED: 'RETIREMENT_ROTH_RIA_MANAGED',
  TRUST_RIA_MANAGED: 'TRUST_RIA_MANAGED',
  HSA_RIA_MANAGED: 'HSA_RIA_MANAGED',
};

export const riaManagementTypes = [
  RIATypes.RIA_MANAGED,
  RIATypes.CUSTODIAL_MANAGED,
  RIATypes.RETIREMENT_TRADITIONAL_RIA_MANAGED,
  RIATypes.RETIREMENT_ROTH_RIA_MANAGED,
  RIATypes.TRUST_RIA_MANAGED,
  RIATypes.HSA_RIA_MANAGED,
];