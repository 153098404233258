import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import fundPortfolios from '../../components/FundsPortfolios/modules/reducer';
import { moneyMovementReducer } from '../../components/MoneyMovement/modules/reducer';
import roles from '../../components/Permissions/modules/reducer';
import settingsReducer from '../../modules/settings/reducer';
import batch from '../../routes/Batch/modules/reducer';
import batchPreview from '../../routes/Batch/routes/BatchPreview/modules/reducer';
import { instrumentGroupPageReducer } from '../../routes/InstrumentGroups/modules/reducer';
import instrumentsReducer from '../../routes/Instruments/modules/reducer';
import { marginCallReducer } from '../../routes/MarginCall/modules/reducer';
import { permissionsReducer } from '../../routes/Permissions/modules/reducer';
import { principalWorkflowReducer } from '../../routes/PrincipalApproval/modules/reducer';
import { accountViolationsReducer } from '../../routes/Reporting/routes/AccountViolations/modules/reducer';
import { accountReducer } from '../../routes/User/routes/Account/modules/reducer';
import { beneficiariesPageReducer } from '../../routes/User/routes/Account/routes/Beneficiaries/modules/reducer';
import { createViolationReducer } from '../../routes/User/routes/Account/routes/CreateViolation/modules/reducer';
import { portfolioPageReducer } from '../../routes/User/routes/Account/routes/Portfolio/modules/reducer';
import { reportsReducer } from '../../routes/User/routes/Account/routes/Reports/modules/reducer';
import { shareTransferReducer } from '../../routes/User/routes/Account/routes/ShareTransfer/modules/reducer';
import { userTransactionsReducer } from '../../routes/User/routes/Account/routes/Transactions/modules/reducer';
import { bankAccountsReducer } from '../../routes/User/routes/BankAccounts/modules/reducer';
import { createAllocationsReducer } from '../../routes/User/routes/CreateAllocation/modules/reducer';
import { productsReducer } from '../../routes/User/routes/Products/modules/reducer';
import { depositsReducer } from '../../routes/User/routes/RecurringDeposits/modules/reducer';
import { subscriptionsPageReducer } from '../../routes/User/routes/Subscriptions/modules/reducer';
import { wlpPageReducer } from '../../routes/WLP/modules/reducer';
import accountOrders from './accountOrders';
import accounts from './accounts/reducer';
import advisors from './advisors/reducer';
import bankAccounts from './bankAccounts/reducer';
import commissions from './commissionSchedules/reducer';
import corporateActions from './corporateActions/reducer';
import finTranTypes from './finTranTypes/reducer';
import instrumentGroups from './instrumentGroups/reducer';
import commonInstruments from './instruments/reducer';
import statusReducer from './status';
import subscriptions from './subscriptions/reducer';
import team from './team/reducer';
import users from './users/reducer';
import wlp from './wlp/reducer';

const authPersistConfig = {
  keyPrefix: 'reduxPersist:',
  key: 'auth',
  storage,
};
const settingsPersistConfig = {
  keyPrefix: 'reduxPersist',
  key: 'settings',
  storage,
};

const makeRootReducer = asyncReducers =>
  combineReducers({
    account: accountReducer,
    batch,
    batchPreview,
    createAllocations: createAllocationsReducer,
    instruments: instrumentsReducer,
    settings: persistReducer(settingsPersistConfig, settingsReducer),
    status: statusReducer,
    form: formReducer,
    users,
    advisors,
    accounts,
    commissions,
    corporateActions,
    roles,
    finTranTypes,
    fundPortfolios,
    commonInstruments,
    team,
    subscriptions,
    bankAccounts,
    instrumentGroups,
    wlp,
    userTransactions: userTransactionsReducer,
    accountViolations: accountViolationsReducer,
    recurringDeposits: depositsReducer,
    reports: reportsReducer,
    createViolation: createViolationReducer,
    moneyMovement: moneyMovementReducer,
    shareTransfer: shareTransferReducer,
    beneficiariesPage: beneficiariesPageReducer,
    instrumentGroupPage: instrumentGroupPageReducer,
    portfolioPage: portfolioPageReducer,
    bankAccountsPage: bankAccountsReducer,
    subscriptionsPage: subscriptionsPageReducer,
    productsPage: productsReducer,
    wlpPage: wlpPageReducer,
    permissions: permissionsReducer,
    principalWorkflow: principalWorkflowReducer,
    marginCall: marginCallReducer,
    accountOrders,
    ...asyncReducers,
  });
export default makeRootReducer();
