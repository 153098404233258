// @ts-ignore
import { templates } from './templates';

export const copyText = {
  acat: {
    acatTransfer: 'ACAT Transfer',
    minorGraduationTransfer: 'Minor Graduation Transfer',
    sourceAccount: 'Source Account',
    sourceAccountType: 'Source Account Type',
    destinationAccount: 'Destination Account',
    destination: 'Destination',
    clearingNo: 'Clearing No.',
    acatType: 'ACAT Type',
    positions: 'Positions',
    createGraduationTransfer: 'Create graduation transfer',
    acatStatusHasBeenSuccessfullyUpdated: 'ACAT status has been successfully updated',
    errorInUpdatingAcatStatus: 'Error in updating ACAT status',
    inLabel: 'In',
    outLabel: 'Out',
    inCaps: 'IN',
    outCaps: 'OUT',
    acatStatusUpdate: 'ACAT Status Update',
    viewTabWithUpdatedTransfer: 'View tab with updated transfer',
    cancelTransfer: 'Cancel Transfer',
    createAcatTransfer: 'Create ACAT transfer',
    pleaseEnterAValidInstrumentId: 'Please enter a valid instrument ID',
    acatHasBeenSuccessfullyCreated: 'ACAT has been successfully created',
    positionLabel: 'Position',
    addAnotherSymbol: 'Add another symbol',
    quantityMustBeGreaterThan0: 'Quantity must be greater than 0',
    symbolIsRequired: 'Symbol is required',
    individual: 'Individual',
    corporate: 'Corporate',
    joint: 'Joint',
    custodial: 'Custodial',
    iraCaps: 'IRA',
    iraManaged: 'IRA Managed',
    full: 'Full',
    partial: 'Partial',
    qtyCaps: 'QTY',
    settlementInfoHeader: 'Settlement Info',
    rejectionInfoHeader: 'Rejection Info',
    controlNoLabel: 'Control No.',
    status724Label: 'Status 724',
    rejectionCodeLabel: 'Rejection Code',
    rejectionReasonLabel: 'Rejection Reason',
  },
  manageExclusionsTable: {
    manageExclusions: 'Manage Exclusions',
    partner: 'Partner',
    account: 'Account No.',
    reason: 'Reason',
    updatedAt: 'Updated At',
    updatedBy: 'Updated By',
    comment: 'Comment',
  },
  batches: {
    backToBatchList: 'Back to Batch List',
    accountEditCopy: 'Account Edit',
    lockUnlockPositionCopy: 'Lock/Unlock Position',
    cashTransferCopy: 'Cash Transfer',
    shareTransferCopy: 'Share Transfer',
    orderCancelCopy: 'Order Cancel',
    instrumentAddCopy: 'Instrument Add',
    executedAwayOrderCopy: 'Executed Away Order',
    optionsLiquidationCopy: 'Options Liquidation',
    orderStatusCopy: 'Order Status',
    depositCopy: 'Deposit',
    positionAdjustmentCopy: 'Position Adjustment',
    financialTransactionCopy: 'Financial Transaction',
    orderAddCopy: 'Order Add',
    cancelRebillCopy: 'Cancel & Rebill',
    showAll: 'Show all',
    showErrorsOnly: 'Show errors only',
    batch: 'Batch',
    batchDetails: 'Batch Details',
    tryAgainLater: '. Please try again later.',
    doNotSendNotification: 'Do not send notification',
    pleaseNote: 'Please note:',
    timeCheckTemplate: templates.timeCheckTemplate,
    transactionLimitTemplate: templates.transactionLimitTemplate,
    invalidMissingData:
      'These lines contain invalid or missing data and cannot be uploaded. Please cancel the batch and fix these lines.',
    batchPreview: 'Batch Preview',
    selectBatchType: 'Select Batch Type',
    downloadTemplateFile: 'Download Template File',
    exampleBatchFileTemplate: templates.exampleBatchFileTemplate,
    cancelBatch: 'Cancel Batch',
    createBatch: 'Create Batch',
    closeBatch: 'Close Batch',
    header: 'What would you like to do?',
    previewAndSubmit: 'Preview & Submit',
    chooseFile: 'Choose file',
    noFile: 'There was no file',
    missingColumnsTemplate: templates.missingColumnsTemplate,
    invalidFileNameTemplate: templates.invalidFileNameTemplate,
    uploadFileTemplate: templates.uploadFileTemplate,
    parseExistingFileName:
      'Filename invalid: This batch file may have already been uploaded. Please upload a batch file with a unique name.',
    submitExistingFileName:
      'Filename invalid: This batch file may have already been uploaded. Please cancel this batch and upload a batch file with a unique name.',
    filterByBatchType: 'Filter by batch type...',
    filterByStatus: 'Filter by status...',
    filterByUser: 'Filter by user...',
    createNewBatch: 'Create New Batch',
    recentBatches: 'Recent Batches',
    processingBatches: 'Processing Batches',
    batchOrders: 'Batch Orders',
    invalidLockErrorMessage: 'The reason for lock must be: ACAT, DTC, DRS, or DWAC',
    invalidUnlockErrorMessage:
      'The reason for unlock must be: transfer_completed or transfer_rejected',
    missingLockReason: 'A valid reason is required',
    accountPermissionCryptoError:
      'Your account does not have permission to perform FinTran batch operations with crypto accounts',
    accountPermissionEquitiesError:
      'Your account does not have permission to perform FinTran batch operations with cash/margin accounts',
    valueMustBe: 'The value must be: ',
    inprocessBatches: 'In-Process Batches',
    processedBatches: 'Processed Batches',
    batchType: 'Batch Type',
    status: 'Status',
    uploadedFilename: 'Uploaded Filename',
    dateCreated: 'Date Created',
    dateUpdated: 'Date Updated',
    updatedBy: 'Updated By',
    completed: 'Completed',
    completedWithErrors: 'Completed with Errors',
    failed: 'Failed',
    view: 'View',
    last2Days: 'Last 2 Days',
    last1Month: 'Last 1 Month',
    last2Months: 'Last 2 Months',
    dateRangeText: 'Date Range',
  },
  navbar: {
    searchForEquitiesAndMutualFunds:
      'Search for Equities and Mutual Funds by ID and Symbol right here.',
    avatar: {
      manageTeam: 'Manage Team',
      managePartners: 'Manage Partners',
      logout: 'Logout',
    },
    environmentSelector: {
      yourRole: 'Your Role',
    },
    useEnvironmentDisabled: {
      noAccessRights: 'You do not have any access rights for this environment.',
      companyNotSetup: 'Your company is not yet set up to access this environment.',
    },
    routes: {
      settings: 'Settings',
      platformSettings: 'Platform Settings',
      manageCustomers: 'Manage Customers',
      manageTeam: 'Manage Team',
      managePartners: 'Manage Partners',
      rolesAndPermissions: 'Roles & Permissions',
      managePermissions: 'Permissions',
      managePermissionGroups: 'Permission Groups',
      manageRoles: 'Roles',
      dashboard: 'Dashboard',
      clients: 'Clients',
      create: 'Create',
      documentApproval: 'Document Approval',
      principalApproval: 'Principal Approval',
      virtualAccounts: 'Virtual Accounts',
      deposits: 'Deposits',
      withdrawals: 'Withdrawals',
      subscriptions: 'Subscriptions',
      reconciliations: 'Reconciliations',
      bulkFunding: 'Bulk Funding',
      settlements: 'Settlements',
      cancelAndRebill: 'Cancel and Rebill',
      assetTransfer: 'Asset Transfer',
      partners: 'Partners',
      createAdvisor: 'Create Advisor',
      createWLP: 'Create WLP',
      permissions: 'Permissions',
      wlpFintranTypes: 'WLP FinTran Types',
      instruments: 'Instruments',
      search: 'Search',
      instrumentGroups: 'Instrument Groups',
      createInstrumentGroup: 'Create Instrument Group',
      batch: 'Batch',
      createBatch: 'Create Batch',
      viewRecentBatches: 'View Recent Batches',
      reports: 'Reports',
      marginCalls: 'Margin Calls',
      otherReports: 'Other Reports',
      blotter: 'Blotter',
      requests: 'Requests',
      omni: 'Omni',
      uploadErrors: 'Upload Errors',
      dailyLendable: 'Daily Lendable',
      developerTools: 'Developer Tools',
      developerDashboard: 'Developer Dashboard',
      keys: 'Keys',
      messageQueue: 'Message Queue',
      sandbox: 'Sandbox',
      manageExclusions: 'Manage Exclusions',
    },
    help: {
      openASupportTicket: 'Open a support ticket',
      apiReference: 'API reference',
      partnerPlaybook: 'Partner playbook',
      knowledgeBase: 'Knowledge base',
      updatesAndChanges: 'Updates & changes',
      apiTermsOfUse: 'API terms of use',
      apiTermsOfUseModal: {
        lastUpdated: 'Last updated:',
        agreeAndContinue: 'Agree & continue',
        termsSuccessfullyAccepted: 'The terms of service have been successfully accepted',
        errorAcceptingTerms: 'There has been an error in accepting the terms of service',
      },
    },
    iconNames: {
      help: 'Help',
      search: 'Search',
    },
  },
  common: {
    clear: 'Clear',
    add: 'Add',
    search: 'Search...',
    month: 'Month',
    date: 'Date',
    cancel: 'Cancel',
    submit: 'Submit',
    leaveBlankForDefaut: 'Leave blank for default',
    selectOne: 'Select one…',
    details: 'Details',
    commonDelete: 'Delete',
    disable: 'Disable',
    deFault: 'Default',
    error: 'Error',
    success: 'Success',
    commonAmount: 'Amount',
    ok: 'OK',
    save: 'Save',
    reset: 'Reset',
    apply: 'Apply',
    edit: 'Edit',
    symbol: 'Symbol',
    time: 'Time',
    quantity: 'Quantity',
    of: 'of',
    startDate: 'Start Date',
    endDate: 'End Date',
    all: 'All',
    comment: 'Comment',
    min: 'Min',
    max: 'Max',
    from: 'From',
    to: 'To',
    mandatoryFields: '*- Mandatory Fields',
    upload: 'Upload',
    downloadReport: 'Download report',
    downloadExceptionsReport: 'Download exceptions report',
    manual: 'Manual',
    automatic: 'Automatic',
    approve: 'Approve',
    approvedBy: 'Approved By',
    reject: 'Reject',
    commonReturn: 'Return',
    monthAndYear: 'Month and Year',
    download: 'Download',
    reason: 'Reason',
    close: 'Close',
    print: 'Print',
    noResultsFound: 'No results found.',
    quarter: 'Quarter',
    quarterly: 'Quarterly',
    monthly: 'Monthly',
    downloadAs: 'Download As...',
    yes: 'Yes',
    no: 'No',
    saveChanges: 'Save Changes',
    update: 'Update',
    clickHere: 'Click here',
    toDownloadYourReport: 'to download your report.',
    today: 'Today',
    welcome: 'Welcome',
    country: 'Country',
    view: 'View',
    taxCenter: 'Tax Center',
    anUnknownErrorOccurred: 'An unknown error occurred.',
    confirm: 'Confirm',
    confirmApproval: 'Confirm Approval',
    confirmMarkingAsSuccessful: 'Confirm Marking as Successful',
    buy: 'Buy',
    sell: 'Sell',
    historyCopy: 'History',
    modify: 'Modify',
    nA: 'N/A',
    more: 'More',
    nameCopy: 'Name',
    selectAll: 'Select all',
    copiedToClipboard: 'Copied to clipboard',
    none: 'None',
    at: 'at',
    isRequired: 'is required',
    copiedToClipboardError: 'An error occurred while copying to clipboard',
    copy: 'Copy',
    complete: 'Complete',
    next: 'Next',
    back: 'Back',
    qty: 'Qty',
    step: 'Step',
    pdf: 'PDF',
    csv: 'CSV',
    json: 'JSON',
    true: 'True',
    false: 'False',
    advancedOptions: 'Advanced options',
    whiteScreenError: 'An unexpected error occurred. Please refresh the page and try again.',
    backTo: 'Back to ',
    never: 'Never',
    partners: 'Partners',
    run: 'Run',
    selected: 'selected',
    lastUpdated: 'Last updated',
    noteSubject: 'Note Subject',
    partnerIBID: 'Partner IBID',
    newCaps: 'NEW',
  },
  accounts: {
    excludeFromSettlement: 'Account Excluded from Settlements',
    lastActivityDate: 'Last Activity Date',
    excludeAccount: 'Exclude Account from Settlements',
    accountStatus: 'Account Status',
    accountIDcopy: 'Account ID',
    accountNickname: 'Account Nickname',
    exclusionReason: 'Exclusion Reason',
    acctExclusionComment: 'Comment',
    available: 'Available',
    changeCommission: 'Change commission',
    changeCommissions: 'Change commissions',
    basketPricing: 'Basket pricing',
    account: 'Account',
    transfer: 'Transfer',
    fpsl: 'FPSL',
    from: 'from',
    to: 'to',
    specifyReasonForClosing: 'Please Specify the Reason for Closing the Account',
    specifyReasonForReOpening: 'Please Specify the Reason for Re-opening the Account',
    specifyReasonForStatusChange: 'Please Specify the Reason for Status Change',
    other: 'Other',
    editAccount: 'Edit Account',
    pleaseSelectAReason: 'Please select a reason',
    lockPosition: 'Lock Position',
    unlockPosition: 'Unlock Position',
    lockUnlockPosition: 'Lock/Unlock Position',
    positionAdjustment: 'Position Adjustment',
    newSymbolOrder: 'New %FIELD% Order',
    theSymbolPositionHasBeenSuccessfully: 'The %FIELD% position has been successfully',
    locked: 'locked',
    unlocked: 'unlocked',
    addOrderOrPosition: 'Add Order or Position',
    newOrder: 'New Order',
    newExchangeOrder: 'New Exchange Order',
    executedAwayOrder: 'Executed Away Order',
    addOrder: 'Add Order',
    liquidateAllPositions: 'Liquidate All Positions',
    show: 'Show…',
    finra3210ComplianceEntity: 'Finra 3210 Compliance Entity',
    instrumentInactive: 'Instrument Inactive',
    positionLocked: 'Position Locked',
    noTransactionsFound: 'No transactions found.',
    violations: 'Violations',
    deleteViolation: 'Delete Violation',
    deletePatternDayTrade: 'Delete Pattern Day Trade',
    violationSuccessfullyDeleted: 'Violation successfully deleted',
    patternDayTradeSuccessfullyDeleted: 'Pattern Day Trade successfully deleted',
    errorDeletingViolation: 'An error occurred while trying to delete violation %FIELD%',
    errorDeletingPatternDayTrade:
      'An error occurred while trying to delete pattern day trade %FIELD%',
    beneficiaries: 'Beneficiaries',
    primaryBeneficiaries: 'Primary Beneficiaries',
    contingentBeneficiaries: 'Contingent Beneficiaries',
    createPrimaryBeneficiaries: 'Create Primary Beneficiaries',
    createContingentBeneficiaries: 'Create Contingent Beneficiaries',
    deleteAllBeneficiaries: 'Delete All Beneficiaries',
    allocations: 'Allocations',
    createAllocation: 'Create Allocation',
    products: 'Products',
    createProduct: 'Create Product',
    funds: 'Funds',
    createFund: 'Create Fund',
    portfolios: 'Portfolios',
    createPortfolio: 'Create Portfolio',
    autopilot: 'Autopilot',
    positions: 'Positions',
    orders: 'Orders',
    portfolio: 'Portfolio',
    patternDayTrades: 'Pattern Day Trades',
    patternDayTrader: 'Pattern Day Trader',
    crypto: 'Crypto',
    managemetType: 'Management Type',
    leverage: 'Leverage',
    bodBuyingPower: 'BOD Buying Power',
    bodCashAvailableForWithdrawal: 'BOD Cash Available For Withdrawal',
    bodCashBalance: 'BOD Cash Balance',
    interestFree: 'Interest Free',
    restrictAccount: 'Restrict Account',
    sweepAccountIntoMMF: 'Sweep Account Into MMF',
    allowedToGoNegative: 'Allowed To Go Negative',
    gfvPdtExempt: 'GFV/PDT Exempt',
    allowedToTradeSpecialOrderTypes: 'Allowed To Trade Special Order Types',
    accountPurpose: 'Account Purpose',
    nickname: 'Nickname',
    tradingType: 'Trading Type',
    tradingTypeLabel: 'Trading Type',
    investorType: 'Investor Type',
    accounts: 'accounts',
    investingType: 'Investing Type',
    advisoryLabel: 'Advisory',
    brokerManagedLabel: 'Broker Managed',
    cashManagementLabel: 'Cash Management',
    consolidatedMarginLabel: 'Consolidated Margin',
    custodialLabel: 'Custodial',
    custodialAltLabel: 'Custodial Alternative Investment',
    custodialManagedLabel: 'Custodial Managed',
    hsaAdvisoryLabel: 'Health Savings Managed Account',
    hsaSelfLabel: 'Health Savings Self-Directed Account',
    hsaRiaManaged: 'Health Savings RIA Managed Account',
    reservedLabel: 'Reserved',
    retirementRothAdvisoryLabel: 'Roth IRA - Advisory',
    retirementRothManagedLabel: 'Roth IRA - RIA Managed',
    retirementRothSelfLabel: 'Roth IRA - Self-Directed',
    retirementTraditionalLabel: 'Traditional IRA - Advisory',
    retirementTraditionalManagedLabel: 'Traditional IRA - RIA Managed',
    retirementTraditionalSelfLabel: 'Traditional IRA - Self-Directed',
    riaManagedLabel: 'RIA Managed',
    selfLabel: 'Self-Directed',
    smsfLabel: 'Self Managed Super Fund - SMSF',
    smsfManagedLabel: 'Self Managed Super Fund - SMSF Managed',
    trustAdvisoryLabel: 'Trust Advisory',
    trustManagedLabel: 'Trust RIA Managed',
    trustSelfLabel: 'Trust Self-Directed',
    trusteeLabel: 'Trustee',
    trusteeManagedLabel: 'Trustee Managed',
    restricted: 'Restricted',
    liquidatingTradesOnly: 'Liquidating Trades Only',
    open: 'Open',
    closed: 'Closed',
    pending: 'Pending',
    error: 'Error',
    gfvRestricted: 'GFV Restricted',
    pdtRestricted: 'PDT Restricted',
    optionsTrading: 'Options Trading',
    dividendReinvestment: 'Dividend Reinvestment',
    optionsLevel: 'Options Level',
    optionsLevelNone: 'None',
    fixedIncomeTrading: 'Fixed Income Trading',
    enrolled: 'Enrolled',
    notEnrolled: 'Not Enrolled',
    lastChanged: 'Last changed',
    yearsOld: 'years old',
    summaryMoneyError: 'There was an error loading money summary.',
    summaryMarginError: 'There was an error loading margin summary.',
    summaryViolationsError: 'There was an error loading violations summary.',
    reasonForUpdatingOptionsTradingEnrollment: 'Reason for updating Options Trading Enrollment',
    pleaseEnterNote: 'Please enter note',
    trustedContact: {
      title: 'Trusted Contact',
      createTrustedContact: 'Create Trusted Contact',
      createTrustedContactSuccessMessage: 'Trusted contact successfully created.',
      createTrustedContactErrorMessage: 'There was an error creating the trusted contact.',
      editTrustedContact: 'Edit Trusted Contact',
      editTrustedContactSuccessMessage: 'Trusted contact successfully edited.',
      editTrustedContactErrorMessage: 'There was an error editing the trusted contact.',
      deleteTrustedContact: 'Delete Trusted Contact',
      deleteTrustedContactSuccessMessage: 'Trusted contact successfully deleted.',
      deleteTrustedContactErrorMessage: 'There was an error deleting the trusted contact.',
      deleteTrustedContactConfirmationMessage:
        'Are you sure you want to delete the trusted contact for this account?',
      firstName: 'First Name',
      lastName: 'Last Name',
      address: 'Address',
      email: 'Email Address',
      phone: 'Phone Number',
      noTrustedContact:
        'This account currently does not have a trusted contact listed. Use the button to the top right to create a trusted contact for this account.',
      edit: 'Edit',
      close: 'Close',
    },
    shareTransfer: {
      transfer: 'Transfer',
      cancelTransfer: 'Cancel',
      shareTransfer: 'Share Transfer',
      availableBalance: 'Available cash balance',
      newBalance: 'New Balance:',
      dataGrid: {
        symbol: 'Symbol',
        quantity: 'Quantity',
        moveLots: 'Move Lots?',
        avgPrice: 'Avg. Price ($)',
        pricePerShare: 'Price/Share',
        method: 'Method',
        batch: 'Batch?',
        fifo: 'FIFO',
      },
      fields: {
        cashAmount: 'Cash Amount',
        amount: 'Amount',
        fromAccount: 'From',
        toAccount: 'To',
        comment: 'Comment',
        commentPlaceholder: 'Write a comment...',
        batch: 'Batch?',
      },
      noSelection: {
        title: 'No Positions Selected',
        message: 'Select Positions in the "From" account to get started.',
      },
      errors: {
        cashAmount: 'A065 Transferring cash outside money movement window is not permitted.',
        shareQuantity: 'A064 Account is short in position for symbol',
        unknown: 'An unknown error occurred. Please contact support.',
      },
      successModal: {
        transferred: 'Transferred',
        transferredAndBatched: 'Transferred and batched',
        cash: 'cash',
        sharesOf: 'shares of',
        at: 'at',
        perShare: 'per share',
        from: 'from',
        to: 'to',
      },
      close: 'Close',
      viewTransactions: 'View Transactions',
    },
    createAccount: {
      successfullyCreatedAccount: 'Successfully created account',
      errorCreatingAccount: 'An error occurred while creating the account - %FIELD%',
      createAccount: 'Create account',
      createAccountAction: 'Create Account',
      accountType: 'Account Type',
      authorizedUsers: 'Authorized Users',
      addAuthorizedUser: 'Add authorized user',
      authorizedUserId: 'Authorized User %FIELD% - User ID',
      factaStatus: 'FACTA Status',
      activeNFFE: 'Active NFFE',
      passiveNFFE: 'Passive NFFE',
      managementType: 'Management Type',
      leverage: 'Leverage',
      violationsExempt: 'Violations Exempt',
      specialOrders: 'Special Orders',
      ignoreBuyingPower: 'Ignore Buying Power',
      portfolio: 'Portfolio',
      startingCash: 'Starting Cash',
      product: 'Product',
      accountPurpose: 'Account Purpose',
      riaID: 'RIA ID',
      tradingType: 'Trading Type',
      noAdditionalUsers: 'No additional users will have access to this account.',
      taxStatusCode: 'Tax Status Code',
      taxRecipientCode: 'Tax Recipient Code',
      unknown: 'Unknown',
      individual: 'Individual',
      corporation: 'Corporation',
      withholdingForeignPartnership: 'Withholding Foreign Partnership',
      withholdingForeignPartnershipTrust: 'Withholding Foreign Partnership Trust',
      trust: 'Trust',
      governmentInternationalOrganization: 'Government International Organization',
      taxExemptOrganization: 'Tax Exempt Organization',
      privateFoundation: 'Private Foundation',
      artistAthlete: 'Artist Athlete',
      estate: 'Estate',
      usBranchTreatedPerson: 'US Branch Treated Person',
      qualifiedIntermediary: 'Qualified Intermediary',
      privateIntermediaryWithholdingGeneral: 'Private Intermediary Withholding General',
      privateIntermediaryWithholdingExemptOrg: 'Private Intermediary Withholding Exempt Org',
      qualifiedIntermediaryWithholdingGeneral: 'Qualified Intermediary Withholding General',
      qualifiedIntermediaryWithholdingExemptOrg: 'Qualified Intermediary Withholding Exempt Org',
      authorizedForeignAgent: 'Authorized Foreign Agent',
      publicPensionFund: 'Public Pension Fund',
      unknownRecepient: 'Unknown Recepient',
      qualifiedSecuritiesLenderIntermediary: 'Qualified Securities Lender Intermediary',
      qualifiedSecuritiesLenderOther: 'Qualified Securities Lender Other',
      maxIFAAmount: 'Max IFA Amount',
      maxIFAIndividualDepositAmount: 'Max IFA Individual Deposit Amount',
    },
    accountTabs: {
      details: 'Details',
      orders: 'Orders',
      positions: 'Positions',
      portfolio: 'Portfolio',
      reports: 'Reports',
      transactions: 'Transactions',
      violations: 'Violations',
      patternDayTrades: 'Pattern Day Trades',
      payments: 'Payments',
      beneficiaries: 'Beneficiaries',
      trustedContact: 'Trusted Contact',
      optionsLevelPretext: 'Options Level:',
    },
    returned: 'Returned',
    successful: 'Successful',
    reinvestMFDividendsAndCapitalGains: 'Reinvest MF Dividends and Capital Gains?',
    reinvestMFDividends: 'Reinvest MF Dividends?',
    reinvestEquitiesDividends: 'Reinvest Equities Dividends?',
    reinvestCapitalGains: 'Reinvest MF Capital Gains?',
    clientHasAgreedToTheMarginMisclosures: 'Client has agreed to the margin disclosures',
    freeTradeBalance: 'Free Trade Balance',
    sweepIntoMoneyMarket: 'Sweep Into Money Market?',
    marginLeverage: 'Margin Leverage',
    accruedInterest: 'Accrued Interest',
    accountDetailsHaveBeenSavedSuccessfully: 'Account details have been saved successfully',
    started: 'Started',
    onHold: 'On Hold',
    rejected: 'Rejected',
    internalSuccess: 'Internal Success',
    internalFailed: 'Internal Failed',
    booksAndRecordsFailed: 'Books and Records Failed',
    booksAndRecordsSuccess: 'Books and Records Success',
    virtual: 'Virtual',
    default: 'Default',
    accountInfo: 'Account Info',
    accountNo: 'Account #',
    routingNo: 'Routing #',
  },
  instrumentRequests: {
    instrumentRequestId: 'instrumentRequestId',
    symbol: 'symbol',
    requestedDate: 'requestedDate',
    isinNumber: 'isinNumber',
    securityName: 'securityName',
    marketCap: 'marketCap',
    lastClose: 'lastClose',
    thirtyDaysAdv: 'thirtyDaysAdv',
    requestedBy: 'requestedBy',
    actionDoneBy: 'actionDoneBy',
    actionDoneOn: 'actionDoneOn',
    comments: 'comments',
    status: 'status',
    iSIN: 'ISIN',
    securityNameLabel: 'Security Name',
    marketCapLabel: 'Market Cap (M)',
    lastCloseLabel: 'Last Close',
    thirtyDaysADVLabel: '30-Days ADV (Shared)',
    requestedByLabel: 'Requested By',
    requestedDateLabel: 'Requested Date',
    approvedRejectedByLabel: 'Approved/Rejected By',
    approvedRejectedOnLabel: 'Approved/Rejected On',
    updateRequestLabel: 'Update request',
    deleteRequestLabel: 'Delete request',
    deleteInstrumentRequestLabel: 'Delete Instrument Request',
    deleteConfirmationModalContent: 'Would you like to delete this instrument request?',
    instrumentRequestsTitle: 'Instrument Requests',
    addNewRequest: 'Add new request',
    approvalSnackbarMessage: 'Instrument request has been successfully approved',
    rejectionSnackbarMessage: 'Instrument request has been rejected',
    errorUpdateStatusSnackbarMessage: 'Failed to update instrument request',
    addInstrumentRequestSuccessSnackbarMessage: 'Instrument request has been successfully added',
    addInstrumentRequestErrorSnackbarMessage: 'Failed to add instrument request',
    deleteInstrumentRequestSuccessSnackbarMessage: 'Instrument request has been deleted',
    deleteInstrumentRequestErrorSnackbarMessage: 'Failed to delete instrument request',
    symbolColon: 'Symbol: ',
    requestedByColon: 'Requested by: ',
    updateStatusTitle: 'Update Status',
    newRequestTitle: 'New Request',
    newInstrumentRequestSubtitle: 'To add a new instrument request, please enter the below details',
    searchBySymbolPlaceholder: 'Search by symbol',
    commentsLabel: 'Comments',
    statuses: {
      APPROVED: 'APPROVED',
      REJECTED: 'REJECTED',
      PENDING: 'PENDING',
      SUCCESSFUL: 'SUCCESSFUL',
      FAILED: 'FAILED',
    },
  },
  manageCustomers: {
    taxCenterUtilityDescription:
      'Tax Center is a utility provided by DriveWealth that enables your customers to directly update their TIN/SSN information and access their tax forms and statements.',
    howCanInactiveUsersSeeTaxForms:
      'How can my inactive users or users with closed account(s) access their tax forms?',
    clickOnThisLinkToTaxCenter:
      'Inactive users or users with closed account(s), can click on this link to retrieve their account number(s) to log in to the Tax Center:',
  },
  managePermissions: {
    permission: 'Permission',
    active: 'Active',
    inactive: 'Inactive',
    yes: 'Yes',
    no: 'No',
    editStatus: 'Edit',
    newStatus: 'New',
    documentation: 'Documentation',
    internalOnlyRecommended: 'Internal Only Recommended',
    permissionId: 'Permission Id',
    managePermissions: 'Manage Permissions',
    permissionList: 'Permissions List',
    status: 'Status',
    permissionSearch: 'Enter permission id',
    createPermission: 'Create new permission',
    createNewPermission: 'Create New Permission',
    editPermission: 'Edit Permission',
    createPermissionSuccess: 'has been successfully created',
    createPermissionFail: 'failed to create',
    editPermissionSuccess: 'has been successfully updated',
    editPermissionFail: 'failed to update',
    enabled: 'Enabled',
    permissionGroupLive: 'Permission Group - Live',
    permissionGroupTest: 'Permission Group - Test',
  },
  manageRoles: {
    roleID: 'Role ID',
    roleName: 'Role Name',
    partnerWhitelist: 'Partner Whitelist',
    selectPartnerWhitelisttoAdd: 'Select partner(s) to add to whitelist',
    defaultDatasrc: 'Default Data Source',
    manageRoles: 'Manage Roles',
    adminRights: 'Admin Rights',
    createNewRole: 'Create new role',
    rolesList: 'Roles List',
    roles: 'Roles',
    test: 'Test',
    live: 'Live',
    role: 'Role ',
    createRoleSuccess: 'has been successfully created',
    editRoleSuccess: 'has been successfully updated',
    invalidRoleId: 'Invalid Role ID',
    editRole: 'Edit Role',
    createNewRoleTitle: 'Create New Role',
    createRoleFail: 'Role failed to create',
    editRoleFail: 'Role failed to update',
    selectAll: 'Select all',
    newText: 'New',
    roleSearch: 'Enter role name',
    searchPartners: 'Search partners',
  },
  permissionGroups: {
    permissionId: 'Permissions ID',
    permissions: 'Permissions',
    enabled: 'Enabled',
    permissionGroup: 'Permission Group',
    createPermissionGroupSuccess: 'has been sucessfully created',
    createPermissionGroupFail: 'failed to create',
    editPermissionGroupSuccess: 'has been successfully updated',
    editPermissionGroupFail: 'failed to update',
    permissionSearch: 'Enter permission group name',
    permissionGroups: 'Permission Groups',
    managePermissionGroups: 'Manage Permission Groups',
    permissionGroupsList: 'Permission Groups List',
    permissionGroupId: 'Permission Group ID',
    description: 'Description',
    createnewPermissionGroup: 'Create new permission group',
    createNewTitle: 'Create New Permission Group',
    editTitle: 'Edit Permission Group',
    permissionGroupName: 'Permission Group Name',
    selectPermissionstoAdd: 'Select permission(s) to add',
    searchPermissions: 'Search permissions',
    cancel: 'Cancel',
    save: 'Save',
    recommendedAll: 'RECOMMENDED FOR ALL',
    recommendedInternal: 'RECOMMENDED FOR INTERNAL',
    selectAll: 'Select all',
    newText: 'New',
    createRoleFail: 'Role failed to create',
    editRoleFail: 'Role failed to update',
  },
  accountOrders: {
    orderSelectDetails: 'Please select cancel details',
    initiator: 'Initiated by',
    entity: 'Canceled by entity',
    manualCancelTerms: 'Check here if this is a manual cancel',
    cancelOrderComment: 'Comment',
    instrumentName: 'Instrument Name',
    customer: 'Customer',
    partner: 'Partner',
    firm: 'Firm',
    assetType: 'Asset Type',
    equities: 'Equities',
    fixedIncome: 'Fixed Income',
    buyOrSellOrder: 'Buy or Sell Order',
    exchangeOrder: 'Exchange Order',
    noOrdersFound: 'No orders found.',
    confirmCancellation: 'Confirm Cancellation',
    areYouSureYouWantToApproveThisRequest: 'Are you sure you want to approve this request?',
    areYouSureYouWantToMarkThisRequestAsSuccessful:
      'Are you sure you want to mark this request as successful?',
    areYouSureYouWantToCancelOrder: 'Are you sure you want to cancel this order?',
    areYouSureYouWantToCancelExchangeOrder: 'Are you sure you want to cancel this exchange order?',
    yourRequestHasBeenSuccessfullyUpdated: 'Your request has been successfully updated',
    cancelAndRebillItemWasSuccessfullyApproved: 'Cancel and Rebill item was successfully approved',
    cancelAndRebillItemWasMarkedAsSuccessful: 'Cancel and Rebill item was marked as successful',
    cancelAndRebillItemWasSuccessfullyRejected: 'Cancel and Rebill item was successfully rejected',
    cancelAndRebillItemWasMovedToOnHold: 'Cancel and Rebill item was moved to On Hold',
    viewApprovedTab: 'View Approved tab',
    viewSuccessfulTab: 'View Successful tab',
    viewOnHoldTab: 'View On Hold tab',
    viewRejectedTab: 'View Rejected tab',
    no: 'No',
    yesCancelOrder: 'Yes, cancel order',
    orderNo: 'Order No.',
    errorAccountNo: 'Error Account No.',
    approvedBy: 'Approved By',
    reviewRequired: 'Review Required',
    symbol: 'Symbol',
    sellSymbolLabel: 'Sell Symbol',
    sellOrderNoLabel: 'Sell Order No.',
    buySymbolLabel: 'Buy Symbol',
    buyOrderNoLabel: 'Buy Order No.',
    sellAveragePriceLabel: 'Sell Average Price',
    buyAveragePriceLabel: 'Buy Average Price',
    sellCumulativeQuantityLabel: 'Sell Cumulative Quantity',
    buyCumulativeQuantityLabel: 'Buy Cumulative Quantity',
    amountCashLabel: 'Amount Cash',
    orderTypeLabel: 'Order Type',
    side: 'Side',
    price: 'Price',
    adjustedPrice: 'Adj. Price',
    profitAndLoss: 'Projected P/L',
    adjustedCommission: 'Adj. Commission',
    priceAdjustment: 'Price Adjustment',
    commissionAdjustment: 'Commission Adjustment',
    cancelRebillID: 'Cancel/Rebill ID',
    limitPrice: 'Limit Price',
    limitYield: 'Limit Yield',
    bonds: 'Bonds',
    triggerPrice: 'Trigger Price',
    size: 'Size',
    created: 'Created',
    expires: 'Expires',
    reconcileOrderStatus: 'Reconcile Order Status',
    cancelOrder: 'Cancel order',
    updateOrder: 'Update order',
    orderEntry: 'Order Entry: #',
    newExchangeOrder: 'New Exchange Order: #',
    shares: 'Shares',
    sharesLowerCase: 'shares',
    cash: 'Cash',
    externalNote: 'External Note',
    internalNote: 'Internal Note',
    commissionOverride: 'Commission Override',
    riaManagedOverride: 'RIA Managed Override',
    riaOverride: 'RIA Override',
    quantityAdjustment: 'Quantity Adjustment',
    commissionID: 'Commission ID',
    orderOverride: 'Order Override',
    overrideAutopilot: 'Override Autopilot',
    marginLiquidation: 'Margin liquidation',
    thisOrderIsPartOfLiquidationEvent: 'This order is part of a margin liquidation event',
    submitOrder: 'Submit order',
    batchThisTransaction: 'Batch this transaction',
    batchToIntelliclear: 'Batch to Intelliclear',
    original: 'Original',
    reconciled: 'Reconciled',
    resting: 'Resting',
    executedBy: 'Executed By',
    successfullyCancelledOrder: 'Successfully cancelled order ',
    anErrorOccurredCancellingOrder: 'An error occurred cancelling order ',
    buy: 'Buy',
    sell: 'Sell',
    best: 'Best',
    ask: 'Ask',
    bid: 'Bid',
    orderSuccessfullyCreatedYourOrderNumberIs: 'Order Successfully Created, Your Order Number is ',
    cancelAndRebillRequestSuccessfullyCreated: 'Cancel and Rebill Request Successfully Created',
    priceAdjustmentPartner: 'Price Adjustment Partner',
    priceAdjustmentVenue: 'Price Adjustment Venue',
    driveWealthTechnicalError: 'DriveWealth Technical Error',
    partnerTechnicalError: 'Partner Technical Error',
    vendorError: 'Vendor Error',
    fraud: 'Fraud',
    driveWealthFraud: 'DriveWealth Fraud',
    adjustedPriceLabel: 'Adjusted Price',
    adjustedCommissionLabel: 'Adjusted Commission',
    cancelAndRebill: 'Cancel and rebill',
    submitCancelAndRebill: 'Submit Cancel & Rebill',
    orderSuccessfullyReconciledYourIrderIumberIs:
      'Order Successfully Reconciled, Your Order Number is ',
    invalidAccountID: 'Invalid Account ID',
    invalidOrderNo: 'Invalid Order Number',
    errorInCreatingAnOrder: 'Error in creating an order',
    errorInFetchingInstrumentName: 'Error in fetching instrument name',
    reasonForCancellation: 'Reason for Cancellation',
    cashAmount: 'Cash Amount',
    calculatedQuantity: 'Calculated Quantity',
    averagePrice: 'Average Price',
    transferPrice: 'Transfer Price',
    accountingMethod: 'Accounting Method',
    avgPrice: 'Avg. Price',
    totalValue: 'Total Value',
    filled: 'Filled',
    noFill: '(No fill)',
    fillPrice: 'Fill Price',
    commission: 'Commission',
    execution: 'Execution',
    routedVia: 'Routed Via',
    execID: 'Exec. ID',
    base: 'Base',
    extraShares: 'Extra Shares',
    exchange: 'Exchange',
    secCaps: 'SEC',
    tafCaps: 'TAF',
    override: 'Override',
    partiallyFilled: 'Partially filled',
    canceled: 'Canceled',
    pendingCancel: 'Pending cancel',
    internalMemo: 'Internal Memo',
    closed: 'Closed',
    tradeDate: 'Trade Date',
    expiration: 'Expiration',
    totalAmount: 'Total Amount',
    status: 'Status',
    statusMessage: 'Status Message',
    accountNumber: 'Account Number',
    partnerName: 'Partner Name',
    timeline: 'Timeline',
    orderDetails: 'Order Details',
    orderDate: 'Order Date',
    timestamp: 'Timestamp',
    leaves: 'Leaves',
    cumulative: 'Cumulative',
    lastOrder: 'Last Order',
    last: 'Last',
    avg: 'Avg',
    SEC: 'SEC',
    TAF: 'TAF',
    messageId: 'Message ID',
    sender: 'Sender',
    timing: 'Timing',
    duration: 'Duration',
    details: 'Details',
    market: 'Market',
    buyOpen: 'Buy Open',
    sellClose: 'Sell Close',
    fees: 'Fees',
    patternDayTrades: 'Pattern Day Trades',
    pdtConfirmationModal: {
      title: 'Confirm Override',
      restrictionOverrideMessage: 'Are you sure you want to override this restriction?',
      clearingOverrideMessage: 'Are you sure you want to clear all PDTs?',
      updatingOverrideMessage: 'Are you sure you want to update PDT?',
      clearAllPDTs: 'Clear all PDTs',
    },
    timeInForce: 'Time in Force',
    extendedHours: 'Extended Hours',
    doNotSendNotification: 'Do Not Send Notification',
    replaced: 'Replaced',
    pendingReplace: 'Pending Replace',
  },
  partner: {
    selectAPartner: 'Select a partner',
    partner: 'Partner',
    partnerCopy: 'Partner',
    parentIB: 'Parent IB',
    parentIBID: 'Parent IBID',
    company: 'Company',
    companyName: 'Company Name',
    createWlp: 'Create WLP',
    wlpId: 'WLP ID',
    commissionGroupId: 'Commission Group ID',
    prefix: 'Prefix',
    prefixPractice: 'Prefix Practice',
    contraSubAccount: 'Contra Sub Account',
    marginDefault: 'Margin Default',
    marginMin: 'Margin Min',
    contactPhoneMobile: 'Contact Phone Mobile',
    contactPhoneWork: 'Contact Phone Work',
    inteliclearReports: 'Inteliclear Reports',
    language: 'Language',
    english: 'English',
    chinese: 'Chinese',
    spanish: 'Spanish',
    portuguese: 'Portuguese',
    country: 'Country',
    addNewPartner: 'Add New Partner',
    activeHeader: 'Active',
    defaultIBInfo: 'Default IB Info',
    defaultIB: 'Default IB',
    basicInfo: 'Basic Info',
    wlpHasBeenSuccessfullyCreated: 'WLP has been successfully created',
    backToPartners: 'Back to Partners',
    partnerDetails: 'Partner Details',
    clearingCode: 'Clearing Code',
  },
  bestEx: {
    downloadBestExReport: 'Download BestEx',
    downloadExceptionsReport: 'Download Exceptions',
    reportTypes: {
      quarterly: 'Quarterly',
      monthly: 'Monthly',
    },
    generateReports: 'Generate Reports',
    errors: {
      reportNotAvailable: 'No report for %DATE% is available.',
      cannotBeNull: '%FIELD% cannot be null.',
      parameterNotFound: 'One or more report parameters are empty.',
      invalidDateFormat: 'Date format is invalid.',
    },
    reportMonth: 'Report Month',
    year: 'Year',
    quarter: 'Quarter',
    quarter1: 'Q1',
    quarter2: 'Q2',
    quarter3: 'Q3',
    quarter4: 'Q4',
    quarterPlaceholder: 'Select A Quarter',
    errorMessage: 'Report Not Found',
    notFoundError: 'Report not found for %FIELD%',
    unknownErrorForReport: 'An unknown error occurred while downloading the %FIELD% report',
    unknownErrorGeneratingReport:
      'An unknown error occurred while generating the report for %FIELD%',
  },
  correspondent: {
    dateInTheFuture: 'Report not found for %FIELD%. Date is in the future.',
  },
  endOfDayReport: {
    errors: {
      reportsNotFoundForPartner: 'Reports not found for your partner',
      reportNotFound: 'Report not found',
    },
  },
  tPlusOne: {
    reportTypes: {
      daily: 'Daily',
    },
    errors: {
      reportNotAvailable: 'No report for %DATE% is available.',
      cannotBeNull: '%FIELD% cannot be null.',
      parameterNotFound: 'One or more report parameters are empty.',
      invalidDateFormat: 'Date format is invalid.',
    },
    reportDate: 'Report Date',
    year: 'Year',
    errorMessage: '404 Report Not Found',
  },
  gfv: {
    reportTypes: {
      daily: 'Daily',
    },
  },
  patternDayTrades: {
    reportTypes: {
      daily: 'Daily',
    },
    errors: {
      reportNotAvailable: 'No report for %DATE% is available.',
      cannotBeNull: '%FIELD% cannot be null.',
      parameterNotFound: 'One or more report parameters are empty.',
      invalidDateFormat: 'Date format is invalid.',
      partnerNotFound: 'Partner cannot be undefined',
    },
    reportDate: 'Report Date',
    year: 'Year',
  },
  secLending: {
    statuses: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      CHANGED: 'Changed',
      DUPLICATE: 'Duplicate',
      NEW: 'New',
      TO_BE_VALIDATED: 'To be validated',
      APPROVED: 'Approved',
      UN_APPROVED: 'Unapproved',
      UPLOADED_TO_ACCOUNTING: 'Uploaded to accounting',
    },
    securitiesLending: 'Securities Lending',
    secLending: 'SecLending',
    errors: {
      reportNotAvailable: 'No report for %DATE% is available.',
      cannotBeNull: '%FIELD% cannot be null.',
      parameterNotFound: 'One or more report parameters are empty.',
      invalidDateFormat: 'Date format is invalid.',
      deleteErrorWithFileName: 'Unable to delete daily interest file %FIELD%',
      uploadErrorWithFileName: 'Error uploading file "%FIELD%".',
    },
    reportTypes: {
      clientDaily: 'Client Daily',
      clientMTD: 'Client MTD',
      clientEOM: 'Client EOM',
      partnerDaily: 'Partner Daily',
      partnerMTD: 'Partner MTD',
      dwMTD: 'DriveWealth MTD',
      supplemental: 'Supplemental Daily',
      collateralReport: 'Collateral Report',
    },
    generateReport: 'Generate report',
    selectTheValuesBelow:
      'Select the values below to configure your report. The report will be available for download once it is generated.',
    reportDate: 'Report Date',
    reportMonth: 'Report Month',
    brokerDailyDetailInfo: {
      brokerDaily: 'Broker Daily: %FIELD%',
      positions: 'Positions: %FIELD%',
      lastUpdatedOn: 'Last updated on: %FIELD%',
      lastUpdatedBy: 'Last updated by: %FIELD%',
    },
    brokerDailyPage: {
      deleteConfirmation: 'Are you sure you want to delete the selected file?',
      deleteSuccessWithFilename: 'Daily interest file %FIELD% deleted successfully.',
      uploadFileSuccessConfirmation: 'Successfully uploaded file %FIELD%.',
      fieldNames: {
        commonFileName: 'File name',
        fileDate: 'File date',
        broker: 'Broker',
        commonStatus: 'Status',
        commonType: 'Type',
        uploadedBy: 'Uploaded by',
        uploadedOn: 'Uploaded on',
        secIDLink: 'Sec ID Link',
        loanRate: 'Loan Rate',
        customerSplitPercent: 'Customer Split %',
        commissionSplitPercent: 'Commission Split %',
        customerRevenue: 'Customer Revenue',
        commissionRevenue: 'Commission Revenue',
      },
    },
    positionsPage: {
      addNewPosition: 'Add new position',
      addNewPositionCaps: 'Add New Position',
      addPositions: 'Add Positions',
      editPosition: 'Edit Position',
      editPositions: 'Edit Positions',
      confirmDeleteSelectedPosition: 'Are you sure you want to delete the selected position?',
      confirmDeleteSelectedPositionFile:
        'Are you sure you want to delete the selected position file?',
      successfulDelete: 'Position deleted successfully',
      deleteError: 'Unable to delete the position',
      deleteErrorWithFileName: 'Position delete failed %FIELD%',
      successfulEdit: 'Success editing position',
      editError: 'Error editing position',
      successfulAdd: 'Success adding position',
      addError: 'Error adding position',
    },
    omniPage: {
      totalClientAccrued: 'Total Client Accrued ($)',
      totalPartnerAccrued: 'Total Partner Accrued ($)',
      collateral: 'Collateral ($)',
      accruedRebate: 'Accrued Rebate ($)',
      accountID: 'Account ID',
      daily: 'Daily',
      mtd: 'MTD',
      eom: 'EOM',
      omniPartnerSelect: 'Omni Partner Select',
      omniDataFetchError: 'There was an error fetching Omni details.',
    },
    omniErrorsPage: {
      uploadErrors: 'Upload Errors',
      accountID: 'Account ID',
      symbolCopy: 'Symbol',
      sClose: 'SClose',
      tClose: 'TClose',
      overageTClose: 'Overage TClose',
      warning: 'All updates must be submitted by',
      overageSClose: 'Overage SClose',
      genericError: 'Unknown error',
      rerunFile: 'Re-run file',
    },
    dailyLendablePage: {
      dailyLendable: 'Daily Lendable',
      symbol: 'Symbol',
      cusip: 'Cusip',
      tdQty: 'TDQty',
      sdQty: 'SDQty',
      alreadyLent: 'Already Lent',
      availableToLend: 'Available To Lend',
      recallAmount: 'Recall Amount',
      date: 'Date',
    },
    sigma: {
      count: 'Count:',
      total: 'Total:',
    },
    dailyCalculation: {
      calculationDate: 'Calculation date',
      chooseRecalculationMonth: 'Choose recalculation month',
      recalculationNotice:
        'Note: The entire month calculation will rerun based on updated figures.',
      monthAndYear: 'Month & Year',
      noVersionsAvailable: 'No versions available',
      version: 'Version',
      versionItem: 'Version (%FIELD%)',
    },
    common: {
      recalculate: 'Recalculate',
      unableToUndo: "Note: You can't undo this action.",
    },
    titles: {
      brokerDaily: 'Broker Daily',
      brokerDailyDetail: 'Broker Daily Detail',
      dailyCalculation: 'Daily Calculation',
      dailyCalculationDetail: 'Daily Calculation Detail',
      reconciliation: 'Reconciliation',
      reconciliationHistory: 'Reconciliation History',
      positions: 'Positions',
      positionDetails: 'Position Details',
      reconciliationDetail: 'Reconciliation Detail',
      omni: 'Omni',
    },
    fieldNames: {
      allocationDate: 'Calculation Date',
      broker: 'Broker',
      partner: 'Partner',
      partnerCode: 'Partner Code',
      partnerRate: 'Partner Rate',
      clientRate: 'Client Rate',
      accountNumber: 'Account Number',
      month: 'Month',
      status: 'Status',
      totalAccrued: 'Total Accrued ($)',
      dwAccrued: 'DW Accrued ($)',
      totalDwAccrued: 'Total DW Accrued ($)',
      partnerAccrued: 'Partner Accrued ($)',
      totalPartnerAccrued: 'Total Partner Accrued ($)',
      clientAccrued: 'Client Accrued ($)',
      lastUpdatedBy: 'Last Updated By',
      lastUpdatedOn: 'Last Updated On',
      symbol: 'Symbol',
      sharesOwned: 'Shares Owned',
      sharesLent: 'Shares Lent',
      fileName: 'File Name',
      fileDate: 'File Date',
      accruedInterest: 'Accrued Interest ($)',
      loanDateFrom: 'Loan Date From',
      loanDateTo: 'Loan Date To',
      office: 'Office',
      accruedRebate: 'Accrued Rebate',
      collateral: 'Collateral',
      accountType: 'Account Type',
      currency: 'Currency',
      securityNumber: 'Security Number',
      securityDescription: 'Security Description',
      settleDateCloseQuantity: 'Settle Date Close Quantity',
      systemDate: 'System Date',
      createdDateAndTime: 'Created Date & Time',
      uploadedBy: 'Uploaded By',
      type: 'Type',
    },
    tabs: {
      calculationDate: 'Calculation Date',
      partner: 'Partner',
      customer: 'Customer',
      all: 'All',
      reconciliationTabs: 'reconciliation tabs',
      omni: 'Omni',
    },
    reconciliationHistory: {
      uploadToAccounting: 'Upload to Accounting',
      unapprove: 'Unapprove',
      uploadConfirmation: 'Are you sure you want to upload to accounting?',
      unapproveConfirmation: 'Are you sure you want to unapprove?',
      recalculateConfirmation: 'Are you sure you want to recalculate the selected record?',
      status: {
        unapproved: 'Unapproved',
        approved: 'Approved',
        pending: 'Uploaded to Accounting',
      },
      toasts: {
        unapproveSuccess: 'Successfully unapproved the record.',
        unapproveError: 'Unable to unapprove the record.',
        uploadToAccountingSuccess: 'Successfully uploaded month %FIELD% to accounting.',
        uploadToAccountingError: 'An error occurred while uploading month %FIELD% to accounting.',
        recalculateDateSuccess: "The date '%FIELD%' has been successfully recalculated.",
        recalculateDateError: "An error occurred while recalculating the date '%FIELD%'.",
        recordHasBeenRecalculated: 'The record has been successfully recalculated.',
        recalculateMonthSuccess:
          "Successfully recalculated the month '%FIELD%' for account number ",
        recalculateMonthError:
          "An error occurred while recalculating the month '%FIELD%' for account number ",
        recalculateExactDateSuccess: "Successfully recalculated the date '%FIELD%'.",
        recalculateExactDateError: "An error occurred while recalculating the date '%FIELD%'.",
      },
    },
    reconciliationDetail: {
      recalculateConfirmation: 'Are you sure you want to recalculate the selected record?',
      toasts: {
        recalculateSuccess: 'Record has been recalculated.',
      },
    },
    reconciliation: {
      reconciliationGlobalActions: 'reconciliation global actions',
      confirmationTitle: 'Month & Year: %FIELD%',
      approvalConfirmation: 'Are you sure you want to approve?',
      recalculationConfirmation: 'Are you sure you want to recalculate?',
      toasts: {
        approveSuccess: '%FIELD% has been successfully approved.',
        approveError: 'An error occurred while approving month %FIELD%.',
        recalculateMonthSuccess: '%FIELD% has been successfully recalculated.',
        recalculateMonthError: 'An error occurred while recalculating month %FIELD%.',
      },
    },
    orderTracking: {
      orderTracking: 'Order Tracking',
      orderNo: 'Order No.',
      orderType: 'Order Type',
      side: 'Side',
      status: 'Status',
      symbol: 'Symbol',
      totalOrderAmount: 'Total Order Amount',
      totalQty: 'Total QTY',
      cumQty: 'Cum. QTY',
      leavesQty: 'Leaves QTY',
      lastPrice: 'Last Price',
      avgPrice: 'Avg Price',
      mpid: 'MPID',
      partnerName: 'Partner Name',
      duration: 'Duration',
      accountNo: 'Account No.',
    },
  },
  errors: {
    unknownError: 'Unknown error.',
    anErrorHasOccurred: 'An error has occurred',
  },
  user: {
    documents: 'Documents',
    kycCreated: 'KYC Created',
    kycUpdated: 'KYC Updated',
    kycHeader: 'KYC',
    eKycVerification: 'eKYC Verification',
    reVerifyKyc: 'Re-verify KYC',
    kycMode: 'KYC Mode',
    doKYC: 'Do KYC',
    noKYC: 'No KYC',
    verifyKYC: 'Verify KYC',
    watchlist: 'Watchlist?',
    pleaseSelectDocOrNonDocVerification:
      'Please select documentary or non-documentary verification',
    documentaryVerification: 'Documentary Verification',
    noDocumentationVerification: 'No Documentation Verification',
    noDocumentsSubmitted: 'No documents will be submitted for KYC verification',
    pleaseSelectADocument: 'Please select a document from below for KYC Re-verification',
    selectForKycReVerification: 'Select For KYC Re-verification',
    thankYouForSubmittingVerification:
      'Thank you for submitting your KYC verification. Verifications are not immediate.',
    verificationType: 'Verification Type',
    verificationStatus: 'Verification Status',
    idType: 'ID Type',
    transactionId: 'Transaction ID',
    entityToken: 'Entity Token',
    lastEvaluationToken: 'Last Evaluation Token',
    numberOfReviews: 'Number of Reviews',
    reviewOnAlloyCopy: 'Review on Alloy',
    documentReviewed: 'Document Reviewed',
    nonDocumentaryReview: 'Non-Documentary Review',
    reviewComments: 'Review Comments',
    personalDetails: 'Personal Details',
    dateOfBirth: 'Date of Birth',
    gender: 'Gender',
    maritalStatus: 'Marital Status',
    userPoliticallyExposed: 'User is politically exposed',
    numberOfDependents: 'Number of Dependents',
    politicalConnection: 'Political Connection',
    modifyUser: 'Modify User',
    username: 'Username',
    password: 'Password',
    passwordAgain: 'Password (Again)',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    phoneNumber: 'Phone Number',
    email: 'Email',
    emailAddress: 'Email Address',
    invalidEmail: 'Invalid Email',
    createUser: 'Create User',
    userDetails: 'User Details',
    compliance: 'Compliance',
    openInGoogleMaps: 'Open in Google Maps',
    updateBOUserPassword: 'Update BO User Password',
    acknowledgments: 'Acknowledgments',
    notes: 'Notes',
    newNote: 'New note',
    recurringDeposits: 'Recurring Deposits',
    bankAccounts: 'Bank Accounts',
    deleteBankAccount: 'Delete Bank Account',
    bankAccountSuccessfullyDeleted: 'Bank Account successfully deleted',
    errorDeletingBankAccount: 'An error occurred while trying to delete the bank account %FIELD%',
    bankAccountDownload: 'bank_accounts',
    accounts: 'Accounts',
    cryptoEnabled: 'Crypto Enabled?',
    rule14b: 'Rule 14b-1(c)',
    rule14bJoint: 'Rule 14b-1(c) (Joint)',
    rule14bUpdated: 'Rule 14b-1(c) updated successfully.',
    acknowledgmentsNotUpdated: 'Error updating acknowledgments',
    optionsAcknowledgmentUpdated: 'Successfully updated Options Acknowledgment',
    optionsAcknowledgmentNotUpdated: 'Error updating Options Acknowledgment',
    lookupReviewer: 'Lookup reviewer',
    created: 'Created',
    lastSignedIn: 'Last Signed In',
    signedApplication: 'Signed Application',
    signedApplicationJoint: 'Signed Application (Joint)',
    customerAgreement: 'Customer Agreement',
    customerAgreementJoint: 'Customer Agreement (Joint)',
    marketData: 'Market Data',
    marketDataJoint: 'Market Data (Joint)',
    marginAgreement: 'Margin Agreement',
    findersFee: 'Finders Fee',
    findersFeeJoint: 'Finders Fee (Joint)',
    foreignFindersFee: 'Foreign Finders Fee',
    foreignFindersFeeJoint: 'Foreign Finders Fee (Joint)',
    dripAcknowledgment: 'Dividend Reinvestment Acknowledgment',
    cryptoAgreement1: 'Crypto Agreement 1',
    cryptoAgreement2: 'Crypto Agreement 2',
    optionsApproval: 'Options Approval',
    LEVEL_1: 'Level 1',
    LEVEL_2: 'Level 2',
    approved: 'Approved',
    failed: 'Failed',
    revoked: 'Revoked',
    cryptoPending: 'Crypto Pending',
    kycd: "KYC'd",
    photoIdApproval: 'Photo ID Approval',
    addressProofApproval: 'Address Proof Approval',
    userId: 'User ID',
    badPasswordAttempts: 'Bad Password Attempts',
    taxStatus: 'Tax Status',
    cannotHaveSpacesInUsername: 'Cannot have spaces in the username',
    noDependents: 'No. of Dependents',
    experience: 'Experience',
    years: 'Years',
    yearsTrading: 'Years Trading',
    tradesPerYear: 'Trades (per year)',
    averageTradeSize: 'Average Trade Size',
    equitiesSuitability: 'Equities Suitability',
    optionsSuitability: 'Options Suitability',
    tbd: 'TBD',
    politicallyExposed: 'Politically Exposed?',
    optionsAgreement: 'Options Agreement',
    fixedIncomeAgreement: 'Fixed Income Agreement',
    none: 'None',
    selectOne: 'Select one...',
    limited: 'Limited',
    good: 'Good',
    excellent: 'Excellent',
    zeroTrades: '0 to 9 Trades',
    tenTrades: '10 to 14 Trades',
    fifteenTrades: '15 to 24 Trades',
    twentyFiveTrades: '25 to 74 Trades',
    seventyTrades: '75+ Trades',
    zeroToTenThousand: '0 to 9999',
    tenToTwentyFiveThousand: '10000 to 24999',
    twentyFiveThousandPlus: '25000 Plus',
    oneToTwoYears: '1 to 2 Years',
    threeToFiveYears: '3 to 5 Years',
    sixToNineYears: '6 to 9 Years',
    tenPlusYears: '10+ Years',
    genericDocumentError: 'Error Downloading Tax Document',
    maxDocumentsError:
      'D100: There was an error in retrieving the document. Details: Maximum number of documents exist for this user.',
  },
  transactions: {
    accountFrom: 'Account From',
    accountTo: 'Account To',
    bankAccountID: 'Bank Account ID',
    paymentStatus: 'Payment Status',
    doNotBatch: 'Do Not Batch',
    addTransaction: 'Add Transaction',
    downloadReport: 'Download Report',
    purchase: 'Purchase',
    sale: 'Sale',
    journal: 'Journal',
    taxWithheld: 'Tax Withheld',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    tradeFunding: 'Trade Funding',
    taxWithholding: 'Tax Withholding',
    cashDeposit: 'Cash Deposit',
    cashDisbursement: 'Cash Disbursement',
    newBalance: 'New Balance',
    addAccountFee: 'Add Account Fee',
    addDeposit: 'Add Deposit',
    addWithdrawal: 'Add Withdrawal',
    typeHeader: 'Type',
    fintrans: {
      doNotReconcile: {
        dialogTitle: 'Do Not Reconcile',
        dialogBody: 'Are you sure you want to continue? This action connot be undone.',
        successMessage: "Transaction successfully marked as 'Do Not Reconcile'",
        errorMessage:
          "An error occurred while marking the transaction as 'Do Not Reconcile'. Please try again.",
        close: 'Close',
        update: 'Update Transactions',
      },
    },
  },
  downloadAlert: {
    clickHereToDownload: 'Please click here to download',
    downloadReport: 'You can now download your report',
  },
  moneyMovement: {
    breakdown: 'Breakdown',
    fullBreakdown: 'Full Breakdown',
    beneficiaryPaymentId: 'Beneficiary Payment ID',
    vendorId: 'Vendor ID',
    statusChangeComment: 'Status Change Comment',
    note: 'Note',
    payablesToDW: 'Payables to DW',
    receivablesFromDW: 'Receivables from DW',
    moneyIn: 'Money In',
    moneyOut: 'Money Out',
    minAmount: 'Minimum Amount',
    maxAmount: 'Maximum Amount',
    fullName: 'Full Name',
    userName: 'Username',
    alternateName: 'Alternate Name',
    configName: 'Config Name',
    processItems: 'Process Items',
    beneficiaryName: 'Beneficiary Name',
    partnerName: 'Partner Name',
    dateTime: 'Date/Time',
    approveReturn: 'Approve/Return',
    approveReject: 'Approve/Reject',
    successReject: 'Success/Reject',
    markAsSuccessful: 'Mark as successful',
    confirmReturn: 'Confirm Return',
    confirmRejection: 'Confirm Rejection',
    pleaseAddAReasonForRejection: 'Please add a reason for rejection',
    yourRequestHasBeenSuccessfullyRejected: 'Your request has been successfully rejected',
    approveLowerCase: 'approve',
    returnLowerCase: 'return',
    pleaseAddTheReasonTo: 'Please add the reason to ',
    lastUpdated: 'Last Updated: ',
    updatedBy: 'Updated By',
    nameChange: 'Name Change',
    configChange: 'Config Change',
    depositNo: 'Deposit # ',
    haveBeenSuccessfullyApproved: 'These account(s) have been successfully approved: ',
    areNowPendingReturn: 'These account(s) are now pending return: ',
    rejectedAccounts: 'These account(s) were not successful: ',
    hasBeenSuccessfullyApproved: ' has been successfully approved.',
    isNowPendingReturn: ' is now pending return.',
    hasBeenSuccessfullyReturned: ' has been successfully returned. ',
    accountNo: 'Account No.',
    searchByAccountNoPlaceholder: 'Search by Account No.',
    achSuspense: 'ACH Suspense',
    wireSuspense: 'Wire Suspense',
    virtualAccountsTabs: {
      all: 'All',
      pending: 'Pending',
      returnPending: 'Return Pending',
      returned: 'Returned',
      successful: 'Successful',
    },
    datePicker: {
      today: 'Today',
      sinceYesterday: 'Since Yesterday',
      threeDays: '3 Days',
      sevenDays: '7 Days',
      thirtyDays: '30 Days',
      sixtyDays: '60 Days',
      ninetyDays: '90 Days',
      custom: 'Custom',
    },
  },
  formValidation: {
    isARequiredField: '%FIELD% is a required field.',
    doesNotMatch: 'Does not match',
    pleaseEnterAValidEmail: 'Please enter a valid email.',
    pleaseEnterAValidAccountNumber: 'Please enter a valid account number.',
    minLowercaseOne: '%FIELD% must contain at least one lowercase character.',
    minUppercaseOne: '%FIELD% must contain at least one uppercase character.',
    minNumberOne: '%FIELD% must contain at least one number.',
    min8Characters: '%FIELD% must be at least 8 characters.',
    min16Characters: '%FIELD% must be at least 16 characters.',
    min4Characters: '%FIELD% must be at least 4 characters.',
    min4Max50Characters: '%FIELD% must be between 4 and 50 characters',
    max50Characters: '%FIELD% must be no more than 50 characters.',
    max89Characters: '%FIELD% must be no more than 89 characters.',
    passwordsDontMatch: "Passwords don't match",
    todayOrBefore: '%FIELD% must be today or earlier.',
    notWeekend: '%FIELD% must not be a weekend day.',
  },
  wlpFintran: {
    addNewTransaction: 'Add new transaction type',
    title: 'WLP FinTran Types',
    description: 'Description',
    wlpFinTranTypeID: 'wlpFinTranTypeID',
    finTranTypeID: 'FinTranTypeID',
    moneyType: 'Money Type',
    clearingAccount: 'Clearing Account',
    batchCode: 'Batch Code',
    active: 'Active',
    inactive: 'Inactive',
    currency: 'Currency',
    wlpID: 'wlpID',
    glCode: 'glCode',
    glSubCode: 'glSubCode',
    partner: 'Partner',
    cash: 'Cash',
    wire: 'Wire',
    ach: 'ACH',
    transactionType: 'Transaction Type',
    createTransactionType: 'Create transaction type',
    editTransactionType: 'Edit transaction type',
    fintranTypeCreated: 'A new WLP FinTran Type has been successfully created.',
    fintranTypeUpdated: 'The WLP FinTran Type you selected has been successfully updated.',
    fintranCreateError:
      'There was an error creating a new Transaction Type. Please try again later.',
    fintranUpdateError:
      'There was an error editing the selected Transaction Type. Please try again later.',
  },
  dataGrid: {
    selectAll: 'Select all',
    filters: 'Filters',
    viewColumn: 'View Columns',
    clearAll: 'Clear all',
    filtersUsed: 'Filters selected',
    filterBy: 'Filter By',
    searchBy: 'Search by %FIELD%',
    noResultsFound: {
      title: 'No results found',
      message: "Try adjusting your search or filter to find what you're looking for.",
      clearFilters: 'Clear Filters',
    },
    filter: 'Filter',
    showColumns: 'Show columns',
    exportToCSV: 'Export to CSV',
  },
  orders: {
    orderNotFound: 'Order not found.',
    pleaseEnterOrderNumber: 'Please Enter Order Number',
    lookupOrder: 'Lookup Order',
    batchToPostTrade: 'Batch to Post-trade',
    orderNotes: 'Order Notes',
    orderID: 'Order ID',
  },
  allocations: {
    allocated: 'Allocated:',
    shares: 'shares',
    completeAllocation: 'Complete Allocation',
    orderNumber: 'Order Number',
    orderNo: 'orderNo',
    accountNo: 'accountNo',
    qty: 'qty',
    useClientFunds: 'useClientFunds',
    comment: 'comment',
    accountNumber: 'Account Number',
    quantity: 'Quantity',
    avgPrice: 'Avg. Price ($)',
    commentPlaceholder: 'Comment',
    useClientFundsPlaceholder: 'Use Client Funds',
    addAccount: 'Add account',
    lookupOrder: 'Lookup order',
    emptyStateTitle: 'Add accounts',
    emptyStateMessage: 'Add accounts to create an allocation',
    completeAllocationButton: 'Complete allocation',
    notEnoughSharesError1: 'Not enough shares in the order to add',
    notEnoughSharesError2: 'shares to account',
  },
  settlements: {
    status: 'Status',
    totalAmount: 'Total Amount',
    partner: 'Partner',
    created: 'Created',
    updated: 'Updated',
    reportId: 'Report ID',
    settlementId: 'Settlement ID',
    settlementDate: 'Settlement Date',
    settle: 'Settle',
    category: 'Category',
    downloadCSV: 'Download CSV',
    exportCopyText: 'Export',
    filterByStatus: 'Filter by Status',
    fundingFor: 'funding for',
    settlementFor: 'settlement for',
    areYouSureYouWantToSettleThis: 'Are you sure you want to settle this',
    bulkFundingFor: 'Bulk funding for',
    settlementsFor: 'Settlements for',
    hasBeenSuccessfullySettled: 'has been successfully settled',
    deposits: 'Deposits',
    withdrawals: 'Withdrawals',
    credits: 'Credits',
    debits: 'Debits',
    thereWasAnErrorFetchingSettlementDetails: 'There was an error fetching settlement details',
    reportHasNotBeenFinalized: 'Report has not been finalized yet.',
    reportIsBeingFinalized: 'Report is in the process of being finalized.',
    reportFinalized: 'Report was finalized.',
    reportSuccessfullySettled: 'Report was successfully settled with no errors.',
    reportUnsuccessfullySettled: 'Report was unsuccessfully settled. No transaction was settled.',
    reportPartiallySettled:
      'Report was partially settled. Some transactions were unsuccessfully settled.',
    reportIsAbleToCalculateOffsetAmount:
      'Reconciliation report is now able to calculate offset amount.',
    reportNeedsToBeReviewedManually: 'Report needs to be reviewed manually.',
    settlementOnTradeAccountsCompletedSuccessfully:
      'Settlement on trade accounts completed successfully.',
    iveConfirmedMySelection: "I've confirmed my selection.",
    thisActionIsIrreversible:
      'Please note that once you click on "Settle", this action is irreversible.',
  },
  uploader: {
    uploadNewFile: 'Upload New File',
    uploadNewFileLowercase: 'Upload new file',
    errorUploading: 'Erroring uploading file "%FIELD%".',
    successUploading: 'Successfully uploaded file "%FIELD%".',
  },
  zendesk: {
    zendeskTitle: 'Support Tickets',
    today: 'Today',
    WTD: 'WTD',
    MTD: 'MTD',
    YTD: 'YTD',
    ticketNumber: 'Ticket Number',
    category: 'Category',
    statusColumn: 'Status',
    requester: 'Requester',
    createdOn: 'Created On',
    responsibleParty: 'Responsible Party',
    criticality: 'Criticality',
    type: 'Type',
    partner: 'Partner',
    pendingStatus: 'Pending partner reply',
    openStatus: 'Open',
    holdStatus: 'On hold with DriveWealth',
    solvedStatus: 'Solved by DriveWealth',
    newStatus: 'New',
    allPartners: 'All Partners',
    noResultsMessage: 'There are currently no active support tickets.',
    noResultsButton: 'Submit a support ticket',
    noResultsHeader: 'All clear!',
    billing: 'Billing',
    complianceAML: 'Compliance::AML',
    corporateActions: 'Corporate Actions',
    complianceConsultation: 'Compliance::Consultation',
    iraHsa: 'IRA/HSA',
    margin: 'Margin',
    mutalFunds: 'Mutual Funds',
    psg: 'PSG',
    reconciliationAndPS: 'Reconciliation and P&S',
    settlementsClearing: 'Settlements/Clearing',
    tax: 'Tax',
    technicalOperations: 'Technical Operations',
    internalTransfer: 'Transfers::Internal Transfer',
    transferIn: 'Transfers::Transfer In',
    transferOutACAT: 'Transfers::Transfer Out::ACAT',
    transferOutDRS: 'Transfers::Transfer Out::DRS',
    transferOutOther: 'Transfers::Transfer Out::Other',
    transition: 'Transitions',
    treasuryDeposit: 'Treasury::Deposit',
    treasuryOther: 'Treasury::Other',
    treasuryWithdrawal: 'Treasury::Withdrawal',
    unsureHighPriority: 'Unsure - High Priority',
    unsureLowPriority: 'Unsure - Low Priority',
  },
  reporting: {
    ticketAudit: {
      pleaseEnterOrderNumberOrOrderId: 'Please enter Order Number or Order ID',
      generateReport: 'Generate Report',
      cancelOrder: 'Cancel Order',
    },
    paymentAudit: {
      pleaseEnterPaymentNumber: 'Please enter payment number',
      timeCreated: 'Time Created',
      timeReturned: 'Time Returned',
      batchDeposit: 'Batch Deposit?',
      transactionCode: 'Transaction Code',
      wlpFinTranTypeID: 'WLP Financial Transaction Type ID',
      finTranReference: 'Financial Transaction Reference',
      seasoningPeriodExpires: 'Seasoning Period Expires',
      seasoningStatus: 'Seasoning Status',
      bankingDetails: 'Banking Details',
      accountHolderName: 'Account Holder Name',
      accountType: 'Account Type',
      accountCountry: 'Account Country',
      routingNumber: 'Routing Number',
      oraganization: 'Organization',
      paymentHistory: 'Payment History',
      returnFinancialTransactionID: 'Return Financial Transaction ID',
      beneficiaryAccountNumber: 'Beneficiary Account Number',
      beneficiaryRoutingNumber: 'Beneficiary Routing Number',
      beneficiaryAccountType: 'Beneficiary Account Type',
      beneficiaryBankName: 'Beneficiary Bank Name',
      beneficiaryBankAddress: 'Beneficiary Bank Address',
      beneficiarySwiftABA: 'Beneficiary Swift ABA',
      intermediaryBankName: 'Intermediary Bank Name',
      intermediarySwift: 'Intermediary Swift',
      paymentIDcopy: 'Payment ID',
    },
  },
  instruments: {
    addInstrument: 'Add instrument',
    createInstrument: 'Create Instrument',
    hasBeenSuccessfullyCreated: 'has been successfully created',
    backToInstrumentsList: 'Back to Instruments List',
    instrumentNotFound: 'Instrument not found',
    searchGroups: 'Search groups...',
    option: 'Option',
    optionChain: 'Option Chain',
    viewOptionChain: 'View Option Chain',
    searchByOSI: 'Search by OSI',
    expirationDateCopy: 'Expiration Date',
    thereWasAnErrorFetchingExpirationDates: 'There was an error fetching expiration dates',
    optionTypeCopy: 'Option Type',
    osiCopy: 'OSI',
    exitToSelectOSI: 'Exit to select OSI',
    enterOSIManually: 'Enter OSI manually',
    symbolSecurityIdentifier: 'Symbol/Security Identifier',
    cusipCaps: 'CUSIP',
    rootSymbolCopy: 'Root Symbol',
    rootID: 'Root ID',
    orderSizeMaxCopy: 'Order Size Max',
    orderSizeMinCopy: 'Order Size Min',
    orderSizeStepCopy: 'Order Size Step',
    strikePriceCopy: 'Strike Price',
    callPutCopy: 'Call/Put',
    callCopy: 'Call',
    putCopy: 'Put',
    limitType: 'Limit Type',
    pricePerCentOfPar: 'Price (% of Par)',
    yieldLabel: 'Yield',
    contractShares: 'Contract Shares',
    contracts: 'Contracts',
    contractsLowerCase: 'contracts',
    exchangeCopy: 'Exchange',
    disabled: 'Disabled',
    disclaimers: 'Disclaimers',
    disclaimersPretext:
      'The following instrument list reflects investment products and securities that are currently supported by the DriveWealth Platform.',
    disclaimersOne:
      'DriveWealth, in its sole discretion, may periodically amend the instrument list.',
    disclaimersTwo:
      'DriveWealth is not responsible for the selection of particular investment products and securities offered by any Introducing Party.',
    disclaimersThree:
      'DriveWealth does not provide tax, suitability, or investment advice of any kind.',
    disclaimersFour:
      'Certain types of investment products and particular securities, including those on the instrument list, may not be available in all jurisdictions and may be subject to trading restrictions or other limitations.',
    disclaimersFive:
      'The Introducing Firm is solely responsible for selecting the investment products and securities to be made available to its customers via its front-end platform and for any suitability determinations related to such selections.',
    disclaimersSix:
      "The Introducing Firm is solely responsible for determinations that selected investment products and securities comply with the Introducing Firm's Applicable Law. Prior to enabling trading in such products, DriveWealth may request additional support from the Introducing Firm that selected investment products and securities comply with Applicable Law.",
    disclaimersSeven: `All inquiries regarding the instrument list should be directed to the Introducing Firm’s designated DriveWealth representative. Pricing terms and other conditions may vary for new instruments.
  `,
    gotIt: 'Got it',
    exportAllCSV: 'Export all as CSV',
    instrumentsList: 'Instruments List',
    instrumentsListDownload: 'instruments_list',
    searchInstruments: 'Search Instruments',
    searchInstrumentsPlaceholder: 'Search instruments…',
    searchForATicker: 'Search for a ticker symbol or company name',
    instrument: 'Instrument',
    create: 'Create',
    modify: 'Modify',
    groupType: 'Group Type',
    include: 'Include',
    exclude: 'Exclude',
    enabled: 'Enabled',
    options: 'Options',
    uploadFileWithOneColumn: 'Upload file with 1 column of symbols titled "Symbol"',
    validateSymbols: 'Validate Symbols',
    tabs: {
      equities: 'Equities',
      fixedIncome: 'Fixed Income',
      mutualFunds: 'Mutual Funds',
      crypto: 'Crypto',
      calls: 'Calls',
      puts: 'Puts',
    },
    statuses: {
      closeOnly: 'Close-only',
      active: 'Active',
      inactive: 'Inactive',
    },
    instrumentsDetail: {
      extendedHours: 'Extended Hours Notional Enabled',
      overnightTrading: 'Overnight Trading Enabled',
    },
    labels: {
      corporateBond: 'Corporate Bond',
      governmentAgencyBond: 'Government/Agency Bond',
      usMunicipalBond: 'US Municipal Bond',
      collateralizedMortgageObligationAssetBackedSecurity:
        'Collateralized Mortgage Obligation/Asset-Backed Security',
      mortgageBackedSecurity: 'Mortgage-Backed Security',
      moneyMarket: 'Money Market',
      commonEquity: 'Common Equity',
      preferredEquity: 'Preferred Equity',
      right: 'Right',
      warrant: 'Warrant',
      option: 'Option',
      future: 'Future',
      swap: 'Swap',
      currency: 'Currency',
      commodity: 'Commodity',
      index: 'Index',
      mutualFundUnitInvestmentTrust: 'Mutual Fund/Unit Investment Trust',
      moneyMarketFund: 'Money Market Fund',
      exchangeTradedFund: 'Exchange Traded Fund',
      hybrid: 'Hybrid',
      nonUsMortgageBackedSecurity: 'Non-US Mortgage-Backed Security',
      compositeUnit: 'Composite Unit',
      debtEquityHybrid: 'Debt/Equity Hybrid',
      strategy: 'Strategy',
      overTheCounterOtc: 'Over-the-Counter (OTC)',
      bankLoan: 'Bank Loan',
      mbsGeneric: 'MBS Generic',
      other: 'Other',
      payFrequencyLabel: 'Pay Frequency',
    },
    payFrequency: {
      annually: 'Annually',
      semiAnnually: 'Semi-Annually',
      quarterly: 'Quarterly',
      monthly: 'Monthly',
      weekly: 'Weekly',
      daily: 'Daily',
      everyXDays: 'Every X Days',
      everyXMonths: 'Every X Months',
      everyXWeeks: 'Every X Weeks',
      everyXYears: 'Every X Years',
      atMaturity: 'At Maturity',
      singleDate: 'Single Date',
      singleInterestPayment: 'Single Interest Payment',
      flexible: 'Flexible',
      notApplicable: 'Not Applicable',
    },
    debtSectors: {
      accomodationAndFoodServices: 'Accommodation and Food Services',
      administrativeAndSupportAndWasteManagementAndRemediationServices:
        'Administrative and Support and Waste Management and Remediation Services',
      agriculureForestryFishingAndHunting: 'Agriculure, Forestry, Fishing, and Hunting',
      artsEntertainmentAndRecreation: 'Arts, Entertainment, and Recreation',
      construction: 'Construction',
      educationalServices: 'Educational Services',
      financeAndInsurance: 'Finance and Insurance',
      healthCareAndSocialAssistance: 'Health Care and Social Assistance',
      information: 'Information',
      managementOfCompaniesAndEnterprises: 'Management of Companies and Enterprises',
      manufacturing: 'Manufacturing',
      miningQuarryingAndOilAndTechnicalServices: 'Mining, Quarrying, and Oil and Gas Extraction',
      otherServicesExceptPublicAdministration: 'Other Services Except Public Administration',
      professionalScientificAndTechnicalServices:
        'Professional, Scientific, and Technical Services',
      publicAdministration: 'Public Administration',
      realEstateAndRentalAndLeasing: 'Real Estate and Rental and Leasing',
      retailTrade: 'Retail Trade',
      transportationAndWarehousing: 'Transportation and Warehousing',
      utilities: 'Utilities',
      wholesaleTrade: 'Wholesale Trade',
    },
    searchByName: 'Search by name',
    searchByISIN: 'Search by ISIN',
    searchBySymbolPlaceholder: 'Search by symbol',
    copyClipboard: 'Copied to clipboard',
    instrumentDetail: 'Instrument Detail',
    instrumentDetails: 'Instrument Details',
    askYtm: 'Ask YTM',
    currentYield: 'Current Yield',
    spreadToTreasuries: 'Spread to Treasuries',
    type: 'Type',
    reutersInstrumentCode: 'Reuters Instrument Code',
    closePrice: 'Close Price',
    houseThresholdOverride: 'House Threshold Override',
    previousClosePrice: 'Previous Close Price',
    exchangeThresholdOverride: 'Exchange Threshold Override',
    orderSizeText: 'Order Size',
    longOnly: 'Long Only?',
    CUSIP: 'CUSIP',
    instrunentType: 'Instrument Type',
    maturity: 'Maturity',
    spRating: 'S&P Rating',
    couponRate: 'Coupon Rate',
    payFrequencyLabel: 'Pay Frequency',
    isin: 'ISIN',
    sedol: 'SEDOL',
    cusip: 'CUSIP',
    limitMustBe5FromPrice: 'Limit must be ±0.05 from price?',
    exchange: 'Exchange',
    investorURL: 'Investor URL',
    sector: 'Sector',
    underlyingAssetType: 'Underlying Asset Type',
    minimumInvestmentAmount: 'Minimum Investment Amount',
    debtType: 'Debt Type',
    incrementalInvestmentAmount: 'Incremental Investment Amount',
    ytm: 'YTM',
    previousYtm: 'Previous YTM',
    maturityDate: 'Maturity Date',
    instrumentID: 'Instrument ID',
    additionalMutalFundDetails: 'Additional Mutual Fund Details',
    additionalDetails: 'Additional Details',
    datedDate: 'Dated Date',
    issueDate: 'Issue Date',
    firstCouponDate: 'First Coupon Date',
    nextCouponDate: 'Next Coupon Date',
    lastCouponDate: 'Last Coupon Date',
    domicileCountry: 'Domicile Country',
    originalIssueAmount: 'Original Issue Amount',
    issuePrice: 'Issue Price',
    issuerInformation: 'Issuer Information',
    couponInformation: 'Coupon Information',
    couponType: 'Coupon Type',
    dayCountType: 'Day Count Type',
    benchmark: 'Benchmark',
    currentRateEffectiveDate: 'Current Rate Effective Date',
    resetFrequency: 'Reset Frequency',
    fundFamily: 'Fund Family',
    fundStatus: 'Fund Status',
    largeTradeNotificationRequired: 'Large Trade Notification Required',
    shareClass: 'Share Class',
    loadType: 'Load Type',
    largeDollarThresholdAmount: 'Large Dollar Threshold Amount',
    redemptionFeePercentage: 'Redemption Fee Percentage',
    dccsEligible: 'DCC&S Eligible',
    redemptionFeeAmount: 'Redemption Fee Amount',
    blueSkyStates: 'Blue Sky States',
    restrictedRedemption: 'Restricted Redemption',
    settlementDays: 'Settlement Days',
    purchaseEligibility: 'Purchase Eligibility',
    purchaseCutoff: 'Purchase Cutoff',
    redemptionCutoff: 'Redemption Cutoff',
    exchangeBuyEligibility: 'Exchange Buy Eligibility',
    exchangeFee: 'Exchange Fee',
    maxPurchaseAmount: 'Max Purchase Amount',
    exchangeFeePercentage: 'Exchange Fee Percentage',
    minInitTaxablePurchase: 'Min Init Taxable Purchase',
    exchangeFeeAmount: 'Exchange Fee Amount',
    minInitIRAPurchase: 'Min Init IRA Purchase',
    dividendFrequency: 'Dividend Frequency',
    minInitQualifiedPurchase: 'Min Init Qualified Purchase',
    operatingExpenses: 'Operating Expenses',
    minSubsequentTaxablePurchase: 'Min Subsequent Taxable Purchase',
    portfolioTurnover: 'Portfolio Turnover',
    minSubsequentIraPurchase: 'Min Subsequent IRA Purchase',
    STRRulesApply: 'STR Rules Apply',
    minSubsequentQualifiedPurchase: 'Min Subsequent Qualified Purchase',
    STRMinimumAmount: 'STR Minimum Amount',
    minSystematicPurchase: 'Min Systematic Purchase',
    mergedSecurity: 'Merged Security',
    shares: 'shares;',
    sharePrecision: 'share precision',
    name: 'Name',
    price: 'Price',
    change: 'Change',
    status: 'Status',
    active: 'Active',
    backToInstruments: 'Back to Instruments List',
    mutualFund: 'Mutual Fund',
    notAvailable: 'Not available',
    bid: 'Bid',
    low: 'Low',
    ask: 'Ask',
    close: 'Close',
    lastTrade: 'Last Trade',
    lastTradeCopy: 'Last Trade',
    priorClose: 'Prior Close',
    volume: 'Volume',
    open: 'Open',
    marketCondition: 'Market Condition',
    high: 'High',
    dataProvider: 'Data Provider',
    quoteInfo: 'Quote Info',
    orderSizeMin: 'Order Size Min',
    orderSizeMax: 'Order Size Max',
    orderSizeStep: 'Order Size Step',
    houseCallPercent: 'House Call Percent',
    exchangeCallPercent: 'Exchange Call Percent',
    enableExtendedHoursNotionalStatus: 'Extended Hours Notional Status',
    maxLogoSize: 'Maximum logo size is 1000x1000px (recommended is 160x160px)',
    maxFileSize: 'Maximum file size is 5MB',
    fileFormats: 'File formats accepted: PNG, GIF, JPG or JPEG',
    modifyInstrument: 'Modify Instrument',
    companyLogo: 'Company logo',
    successfullyUpdated: 'Successfully updated',
    description: 'Description',
    invalidFileUpload: 'The file selected is not a valid selection',
    enumValues: {
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      semiAnnually: 'Semi Annually',
      annually: 'Annually',
      percentage: 'Percentage',
      amount: 'Amount',
      noExchangeFee: 'No Exchange Fee',
      open: 'Open',
      liquidated: 'Liquidated',
      fixedDollar: 'Fixed Dollar',
      merged: 'Merged',
      inactive: 'Inactive',
      active: 'Active',
      closeOnly: 'Close Only',
      all: 'All',
      existingOnly: 'Existing Only',
      closed: 'Closed',
      restrictionsApply: 'Restrictions Apply',
      frontEnd: 'Front End',
      backEnd: 'Back End',
      level: 'Level',
      hybrid: 'Hybrid',
      noLoad: 'No Load',
      other: 'Other',
      adr: 'ADR',
      alternativeAsset: 'Alternative Asset',
      crypto: 'Crypto',
      cyberSecurity: 'Cyber Security',
      energy: 'Energy',
      equity: 'Equity',
      equityIndex: 'Equity Index',
      etf: 'ETF',
      etn: 'ETN',
      fx: 'FX',
      metals: 'Metals',
      mutualFund: 'Mutual Fund',
      interestRate: 'Interest Rate',
      yes: 'Yes',
      no: 'No',
      unknown: 'Unknown',
      none: 'None',
    },
    optionsEnabled: 'Options Enabled',
    symbol: 'Symbol',
    symbolCopy: 'Symbol',
    instrumentType: 'Instrument Type',
    instruments: 'Instruments',
    is871mEligibleLabel: '871 Eligible?',
    PTPLabel: 'PTP?',
    marketTierLabel: 'Market Tier',
    tradeStatus: 'Trade Status',
    urlInvestor: 'URL Investor',
    errorGettingInstruments: 'There was an error getting instruments. Please try again later.',
    nasdaqCapitalMarket: 'Nasdaq Capital Market',
    nasdaqGlobalSelectMarket: 'Nasdaq Global Select Market',
    nasdaqGlobalMarket: 'Nasdaq Global Market',
  },
  marginCalls: {
    marginCallSortPlaceholder: 'Sort Margin Calls by...',
    downloadMarginCallReport: 'Download Margin Call Report',
    selectMarginCallReportDate: 'Select a Margin Call Report Date',
    reportDate: 'Report Date',
  },
  reports: {
    reporting: 'Reporting',
    transactions: 'Transactions',
    correspondentReport: 'Correspondent Report',
    m871transactionReport: '871m Transaction Report',
    exceptionETFStatusReport: 'Exception Report for ETF Status',
    endOfDayReport: 'End of Day Report',
    inteliclear: 'Inteliclear',
    ticketAudit: 'Ticket Audit',
    users: 'Users',
    restrictedUsers: 'Restricted Users',
    accounts: 'Accounts',
    accountViolations: 'Account Violations',
    gfv: 'Good Faith Violations',
    payments: 'Payments',
    paymentAudit: 'Payment Audit',
    timing: 'Timing',
    generateReport: 'Generate Report',
    loadingLatestReport: 'Loading latest reports',
    orderNumberOrOrderId: 'Order Number or Order ID',
    exchangeOrderNumberOrId: 'Exchange Order Number or ID',
    tradingType: 'Trading Type',
    paymentNumber: 'Payment Number',
    paymentID: 'Payment ID',
    orders: 'Orders',
    bestEx: 'BestEx',
    exceptions: 'Exceptions',
    margin: 'Margin',
    tPlusOne: 'T Plus One',
    pdt: 'Pattern Day Trades',
    runReport: 'Run Report',
    identifiers: 'Identifiers',
    exchangeNo: 'Exchange No.',
    currentStatus: 'Current Status',
    quotedCurrency: 'Quoted Currency',
    marketPriceAtEntry: 'Market Price at Entry',
    timeInForce: 'Time in Force',
    timelineCopy: 'Timeline',
    orderDetails: 'Order Details',
    paymentDetails: 'Payment Details',
    accountDetails: 'Account Details',
    requested: 'Requested',
    finalStatus: 'Final Status',
    messageStatus: 'Message Status',
    system: 'System',
    systemUpperCase: 'SYSTEM',
    venue: 'Venue',
    successMessage:
      'Please click the blue Download Report button to download and view your report.',
    autoDownloadSuccessMessage:
      'Report generated successfully. Your download should begin shortly.',
    errorMessage: 'An error occurred while generating your report. Please try again later.',
    backToOtherReports: 'Back to Other Reports',
    reportName: 'Report Name',
    reportType: 'Report Type',
    financialTransactions: 'Financial Transactions',
    cashEvents: 'Cash Events',
    endOfDay: 'End of Day',
    beginningOfDay: 'Beginning of Day',
    reconciliation: 'Reconciliation',
    transaction: 'Transaction',
    type: 'Type',
    metadata: 'Metadata',
    options: 'Options',
    expiration: 'Expiration',
    omniExpiration: 'Omni Expiration',
    titles: {
      reconciliation: 'Reports - Reconciliations',
      transaction: 'Reports - Transactions',
    },
  },
  otherReports: {
    optionsExpiration: {
      mainPage: {
        title: 'Expiring Options',
        subTitle:
          'Please review all positions expiring on expiration day held by customer accounts and take necessary action to mitigate risk on the customer’s behalf by 2:30 PM EST.',
        infoHeader: 'REPORT OVERVIEW',
        infoContent: `This report allows you to manage Option Expiration Day Risk in real time.
        Select parameters to run analysis and get a list of expiring positions that do not have sufficient equity to cover if the contract was exercised.
        Please review all options positions expiring today held by customer accounts and take necessary action to mitigate risk on the customer's behalf by 2:30 PM EST.`,
        analysis: 'Analysis',
        liquidation: 'Liquidation',
        killswitchTooltip:
          'In order to disable the Auto Liquidation process, you must do so before 3:00 PM EST.',
        killswitchDisabledTooltip:
          'Auto Liquidation has been enabled and cannot be reenabled today. It can be enabled tomorrow between 9:00 AM and 3:00 PM ET.',
        killswitchSuccess: 'Auto Liquidation has been disabled for today.',
        disableAutoLiquidation: 'Disable Auto Liquidation',
      },
      analysisPage: {
        tableTitle: 'Expiring Positions',
        accountNo: 'Account No.',
        partnerName: 'Partner Name',
        qtyToBeLiquidated: 'QTY to be Liquidated',
        liquidateAllPositions: 'Liquidate all positions',
        selectParamsToRunAnalysis: 'Select parameters to run analysis:',
        moneyness: 'Moneyness',
        leaveBlankForDefault: 'Leave blank for default of 3%',
        liquidate: 'Liquidate',
        confirmLiquidation: 'Confirm liquidation',
        liquidateAllModalContent: 'Are you sure you want to liquidate all positions?',
        infoHeader: `Moneyness% is an amount that determines what percentage of out-of-the-money (OTM) positions to include in the results.`,
        infoContent: `Example:  If the stock is trading at $100.00 and you select 'Moneyness = 3%', any options positions with a strike price less than $103.00 will be included in the analysis.`,
        liquidateModalContent: 'Are you sure you want to liquidate %FIELD% postion(s)?',
        liquidateSuccessful: ' orders have been successfully sent.',
        liquidateFailed: 'Failed to liquidate positions',
        viewStatus: 'View Status',
        optionsExpirationFetchError: 'Error fetching expiring options',
        symbolSecurityIdentifier: 'Symbol/Security Identifier',
        expirationDateCol: 'Expiration Date',
        strikePrice: 'Strike Price',
        callPut: 'Call/Put',
        noOfContracts: 'No. of Contracts',
        marketValue: 'Market Value',
        accountIDForm: 'Account ID',
        killswitchInfo:
          'Are you sure you want to disable the Automated Liquidated Process for positions expiring today?',
        killswitchEmphasizedInfo: 'This action cannot be reversed.',
        killswitchSuccessful: 'Auto Liquidation process has been canceled.',
        killswitchTitle: 'Disable Auto Liquidation',
      },
      liquidationsPage: {
        liquidatedOn: 'Liquidated On',
        orderStatus: 'Order Status',
        orderNo: 'Order No.',
        orderedQty: 'OrderedQTY',
        filledQty: 'FilledQTY',
        fillPrice: 'Fill Price',
        totalProceeds: 'Total Proceeds',
        optionsLiquidationsFetchError: 'Error fetching liquidations',
      },
    },
  },
  manageTeam: {
    manageTeam: 'Manage Team',
    statuses: {
      CONFIRMED: 'Active',
      FORCE_CHANGE_PASSWORD: 'Pending password change',
      UNCONFIRMED: 'Not confirmed',
      ARCHIVED: 'Disabled',
      COMPROMISED: 'Disabled due to security threat',
      UNKNOWN: 'Unknown',
      RESET_REQUIRED: 'Password reset required',
    },
    labels: {
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      userName: 'Username',
      email: 'Email',
      role: 'Role',
      status: 'Status',
      email2FA: 'Email 2FA',
      MFAOptions: 'Multi-Factor Authentication Options',
      lastLogin: 'Last login',
    },
    createUser: 'Create User',
    updateUser: 'Update User',
    createUserConfirmationTitle: "You're attempting to create a member of a partner's team.",
    createUserConfirmationMessage: 'Are you sure you want to proceed?',
    editUserConfirmationTitle: "You're attempting to edit a member of a partner's team.",
    editUserConfirmationMessage: 'Are you sure you want to proceed?',
    successfulCreate: 'Successfully created user %FIELD%.',
    errorCreate: 'An error occurred while trying to create user.',
    userNameExists: 'User name already exists',
    disableConfirmationTitle: "You're attempting to disable a user.",
    disableConfirmationPartnerTitle: "You're attempting to disable a member of a partner's team.",
    disableConfirmationMessage: 'Are you sure you want to proceed?',
    successfulDisable: 'Successfully disabled user %FIELD%.',
    errorDisable: 'An error occurred while trying to disable user %FIELD%.',
    successfulUpdate: 'Successfully updated user %FIELD%.',
    errorUpdate: 'An error occurred while trying to update user.',
    successfulResend: 'Successfully resent password for user %FIELD%.',
    errorResend: 'An error occurred while trying to resend password for user %FIELD%.',
    resend: 'Resend',
    updateUserTitle: 'Update User - %FIELD%',
    smsDefault: 'The preferred method is SMS. If left unchecked, SMS will be used as the default.',
    neverLoggedIn: 'Never logged in',
    disabled: 'Disabled',
  },
  sessionModal: {
    youWillBeLoggedOut: 'You will be logged out in %FIELD% seconds.',
    continueSessionPrompt: 'Would you like to continue your session or log out?',
    logOut: 'Log out',
    continueSession: 'Continue session',
  },
  reconciliation: {
    errorLoadingBatchDetails: 'An error occurred while loading batch details.',
    recoDate: 'Reco Date',
    partner: 'Partner',
    totalAmount: 'Total Amount',
    created: 'Created',
    updated: 'Updated',
    id: 'ID',
    status: 'Status',
    reconciliations: 'Reconciliations',
    updateReconciliation: 'Update Reconciliation',
    statusCommentLabel: 'Status Comment',
    reconciliationDetailsTable: 'reconciliation details table',
    reconciliationBreakdownIconButton: 'reconciliation breakdown icon button',
    cancel: 'Cancel',
    updateStatus: 'Update status',
    updateSuccess: 'Reconciliation successfully updated',
    updateFail: 'Reconciliation encountered an error while updating',
    APPROVED: 'Approved',
    PENDING: 'Pending',
    SUCCESSFUL: 'Successful',
    REJECTED: 'Rejected',
    SUCCESSFUL_WITH_ERRORS: 'Successful with errors',
    FAILED: 'Failed',
    viewBatch: 'View Batch',
    approved: 'Approved',
    pending: 'Pending',
    successful: 'Successful',
    breakdown: 'Breakdown',
    purchases: 'Purchases',
    sales: 'Sales',
    dividends: 'Dividends',
    fees: 'Fees',
    other: 'Other',
    offset: 'Offset',
    payableToDW: 'Payable to DW',
    receivableFromDW: 'Receivable from DW',
    viewFullBreakdown: 'View full breakdown',
    amount: 'Amount',
    total: 'Total',
  },
  authorization: {
    username: 'Username',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    code: 'Code',
    phoneNumber: 'Phone number',
    passwordRequirements:
      '16 character minimum, 1 lowercase character, 1 uppercase character, 1 number',
    changePassword: 'Change password',
    enterYourPassword: 'Enter your password',
    confirmYourPassword: 'Confirm your password',
    addYourMobileNumber: 'Add your mobile number to verify your account',
    invalidCode: 'Invalid code',
    codeExpired: 'Code expired',
    confirmationCode: 'Confirmation code',
    backToSignIn: 'Back to sign in',
    resetYourPassword: 'Reset your password',
    sendCode: 'Send code',
    enterCode:
      'If you are an existing user, we will send you the code through a text message or email!',
    userNotFound: 'User not found',
    incorrectUsernameOrPassword: 'Incorrect username or password',
    forgotYourPassword: 'Forgot your password?',
    resetPassword: 'Reset password',
    login: 'Login',
    signIn: 'Sign in',
    signInToYourAccount: 'Sign in to your account',
    required: '* Required',
    resendCode: 'Resend code',
    secureYourDriveHubAccountPartOne: 'Secure your DriveHub account for ',
    secureYourDriveHubAccountPartTwo:
      ' with a strong password and two-factor authentication to help protect your data.',
    passwordMustHaveAtLeast: 'Password must have at least: ',
    sixteenCharacters: '16 characters',
    oneNumber: 'One number',
    oneUppercaseCharacter: 'One uppercase character',
    oneLowercaseCharacter: 'One lowercase character',
  },
  positions: {
    positions: 'Positions',
    symbol: 'Symbol',
    price: 'Price',
    quantity: 'Quantity',
    marketValue: 'Market Value',
    costBasis: 'Cost Basis',
    addPosition: 'Add position',
    increasePosition: 'Increase position',
    decreasePosition: 'Decrease position',
    decreasePositionTitle: 'Decrease position of %FIELD% for ',
    increasePositionTitle: 'Increase position of %FIELD% for ',
    positionAddedSuccessfully: 'Position added successfully.',
    positionAddingFailed: 'An error occurred while trying to add a new position.',
    positionDecreasedSuccessfully: 'Position decreased successfully.',
    positionDecreasingFailed: 'An error occurred while trying to decrease a position.',
    positionIncreasedSuccessfully: 'Position increased successfully.',
    positionIncreasingFailed: 'An error occurred while trying to increase a position.',
    addANewPosition: 'Add a new position for %FIELD%',
    current: 'Current: %FIELD%',
    new: 'New: %FIELD%',
    transferCompleted: 'Transfer completed',
    transferRejected: 'Transfer rejected',
    liquidateAllPositions: 'Liquidate all positions',
    liquidationConfirmation:
      'Are you sure you want to liquidate all the positions in this account?',
    liquidateAll: 'Liquidate all',
    successfulOrders: 'Successful orders',
    failedOrders: 'Failed orders',
    skippedOrders: 'Skipped orders',
    positionLockedSuccessfully: 'Position for %FIELD% successfully locked.',
    positionLockingFailed: 'An error occurred while trying to lock position for %FIELD%.',
    lockReason: 'Please select a reason below and provide any additional context.',
    lockPosition: 'Lock position',
    positionUnlockedSuccessfully: 'Position for %FIELD% successfully unlocked.',
    positionUnlockingFailed: 'An error occurred while trying to unlock position for %FIELD%.',
    unlockReason: 'Please select a reason below.',
    unlockPosition: 'Unlock position',
    reason: 'Reason',
    note: 'Note',
    addNote: 'add note',
    transactionWarning: {
      warning:
        "This will not create a financial transaction in the client's account. The account's cash will not be changed.",
      rectifyErrors: 'This functionality should only be used to rectify errors.',
      createANewOrder:
        'To create an order requested by the client, use the "New Order" screen instead.',
      executedAwayOrder:
        'To update the account with an order that was placed manually to an exchange or broker outside of DriveWealth\'s system, use the "Executed Away Order" screen instead.',
    },
    addAPosition: 'Add a position',
    addAnOrder: 'Add an order',
    executeAwayAnOrder: 'Execute away an order',
    locked: 'Locked',
    active: 'Active',
    value: 'Value',
    pl: 'P/L',
    plPercentage: '% P/L',
    lock: 'Lock',
    unlock: 'Unlock',
    equityRequirement: 'Equity Requirement',
    selectAnAccountOption: 'select an account option',
    newPosition: 'New Position',
    createdOn: 'Created On',
  },
  documents: {
    header: 'Documents',
    type: 'Type',
    uploaded: 'Uploaded',
    status: 'Status',
    deleteSuccessMessage: 'Document deleted successfully.',
    deleteErrorMessage: 'Something went wrong. Document not deleted successfully.',
    generateSuccessMessage: 'Tax document generated successfully.',
    generateErrorMessage: 'Something went wrong. Tax document not generated successfully.',
    generateButton: 'Generate tax document',
    deleteButton: 'Delete document',
    addDocument: 'Add document',
    searchByType: 'Search by type',
    deleteModalHeader: 'Confirm deletion',
    deleteModalContent: 'Are you sure you want to delete this document?',
    confirmButton: 'Delete',
    cancelButton: 'Cancel',
    statusId: 'TAX_DOCUMENT',
    frontOfDocument: 'Front of document',
    backOfDocument: 'Back of document',
    frontUploadSuccess: ' front was uploaded successfully',
    backUploadSuccess: ' front was uploaded successfully',
    statuses: {
      pending: 'Pending',
      approved: 'Approved',
      rejected: 'Rejected',
      notSubmitted: 'Not Submitted',
    },
    documentNames: {
      corporate: 'Corporate',
      driverLicense: 'Driver License',
      nationalIDCard: 'National ID Card',
      other: 'Other',
      passport: 'Passport',
      photoID: 'Photo ID',
      proofOfAddress: 'Proof of Address',
      photoIDAndProofOfAddress: 'Photo ID & Proof of Address',
      residencePermit: 'Residence Permit',
      riskAssessment: 'Risk Assessment',
      taxDocument: 'Tax Document',
      trustDocuments: 'Trust Documents',
      workPermit: 'Work Permit',
      visa: 'Visa',
      voterID: 'Voter ID',
      acatsIn: 'ACATs In',
      acatsOut: 'ACATs Out',
      transferOnDeath: 'Transfer On Death Deed',
      onlineAccountApplication: 'Online Account Opening Application Form',
      foreignFindersFeeAcknowledgment: "Foreign Finder's Fee Acknowledgment",
      foreignAdvisor: 'Foreign Advisor',
    },
  },
  partnerOnboarding: {
    companyName: 'Company Name',
    previous: 'Previous',
    fullName: 'Full Name',
    email: 'Email',
    enterValidName: 'Please enter valid name.',
    enterValidUsername: 'Please enter a valid username.',
    getStarted: 'Get started',
    onBoardingError: 'Failed to process onboarding. Try again later',
    formTitle: 'Fill in your details',
    FinancialProductsSupported: 'Financial products supported',
    viewLess: 'View less',
    viewMore: 'View more',
    comingSoon: 'Coming Soon',
    country: 'Country',
    stocks: 'Stocks',
    options: 'Options',
    crypto: 'Crypto',
    mutualFunds: 'Mutual Funds',
    selectYourUseCase: 'Select your use case',
    howDoYouWantToUseDriveWealth: 'How do you want to use DriveWealth?',
    next: 'Next',
    goBack: 'Go back',
    diy: 'DIY (Do-it-yourself)',
    diyText: 'DIY',
    diySolution: 'DIY solution',
    robo: 'Robo',
    roboSolution: 'Robo solution',
    wallet: 'Wallet',
    walletBasedSolution: 'Wallet-based solution',
    omnibus: 'Omnibus',
    omnibusSolution: 'Omnibus solution',
    descriptionDIY: 'Customers pick and choose what to invest in.',
    descriptionRobo: 'Customers are assigned to a customizable portfolio designed by an advisor.',
    descriptionWallet: 'Customers invest directly to and from their wallet balance.',
    descriptionOmnibus: 'A single account to execute and clear all customer orders and positions.',
    description:
      "DriveHub is DriveWealth's dashboard for developers like you to bring your innovative idea to life. Sign up for an account today to get access to your developer keys, view logs, and troubleshoot your implementation.",
    instructions: 'Instructions',
    oneTimeDownload: 'Your sandbox keys are ready to download. They can be downloaded only once.',
    storedInSafeLocation: 'Keep the downloaded file stored in a safe location.',
    protectedSandboxKeys: 'Keep your sandbox keys protected.',
    checkYourEmailFurtherInstructions:
      'Check your email for further instructions to login to DriveHub.',
    downloadSandboxKeys: 'Download Sandbox Keys',
    acceptAll: 'I accept all',
    apiTerms: 'API terms',
    apiTermsAndConditions: 'API terms and conditions',
    asICreateAccount: 'as I create my DriveHub account',
    acceptConditionToCreateAccount: 'and conditions as I create my DriveHub account',
    download: 'Download',
    clickHere: 'Click here',
    returnToLogin: 'Return to login',
    visitDeveloperPortal: 'to visit DriveWealth Developer Portal',
    duplicateEmail: 'Duplicate email',
    unableToVerify: 'Unable to verify Recaptcha',
    checkEmailSnackbarMessage:
      'Please check your email for further instructions on logging into Drivehub.',
    pleaseCheckEmailForCredentials:
      'Please check your email for your temporary login credentials to sign in to DriveHub.',
    otherCountryWarning:
      'DriveWealth may not be able to support your current jurisdiction, limiting you to sandbox access only. Please ',
    contactOurSalesTeam: 'contact our sales team',
    forFurtherDetails: ' for further details.',
    yourAccountHasBeenCreated: 'Your account has been created.',
    username: 'Username',
    usernameHelpertext: 'Must be 4 to 50 characters. Must not have spaces.',
    caseSelection: 'You chose to build a',
    alreadyHaveAnAccount: 'Already have an account?',
    signIn: 'Sign in',
    theUsernameIsAlreadyTaken: 'This username is already taken',
    validEmail: 'Email must be a valid email address.',
    other: 'Other',
  },
  generics: {
    refreshVersionSnackback: 'Please refresh your page for the newest version of DriveHub.',
    offsetPagination: {
      of: 'of',
      moreThan: 'more than',
    },
  },
  orderTypes: {
    market: 'Market',
    stop: 'Stop',
    marketIfTouched: 'Market If Touched',
    limit: 'Limit',
    unknown: 'Unknown',
    marketAndLimitOrders: 'Market & Limit',
    limitOnly: 'Limit Only',
  },
  managePartners: {
    partnerCreatedSuccessful: 'Partner created successfully',
    partnerCreatedFailed: 'An error occured while creating the partner',
    partnerEditSuccessful: 'Partner edit successfully',
    partnerEditFailed: 'An error occured while editing the partner',
    companyName: 'Company Name',
    partnerID: 'Partner ID',
    sandboxIBID: 'Sandbox IBID',
    prodIBID: 'Production IBID',
    active: 'Active',
    managePartners: 'Manage Partners',
    mustBeUUID: 'Must be a UUID',
    min4Characters: 'Must be at least four characters',
    addNewPartner: 'Add New Partner',
    addPartner: 'Add Partner',
    editPartner: 'Edit Partner',
  },
};
